import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import style from './Sidebar.module.sass'
import jwt_decode from 'jwt-decode';

import { FaUsersViewfinder, FaUsersRectangle, FaRegCircleUser } from "react-icons/fa6";
import { MdOutlineContactPage, } from 'react-icons/md';
import { RiHome3Line } from "react-icons/ri";
import { LiaMotorcycleSolid } from "react-icons/lia";
import { IoLogInOutline } from "react-icons/io5";
import { TbUserHexagon } from "react-icons/tb";
import { VscGear } from "react-icons/vsc";
import { HiUserGroup } from "react-icons/hi";
import { FaUserTie } from "react-icons/fa6";

import { getToken, logout } from '../../utils/authService';

//import logo from '../../assets/BLCI-logo.png';

//import logoUser from '../../assets/user.png';

const Sidebar = ({ open, setOpen }) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState(0);
  const token = getToken();
  const { usuario } = jwt_decode(token);
  const [sizeIcons, setSizeIcons] = useState(30);
  const [showConfigButtons, setShowConfigButtons] = useState(false); // Estado para manejar la visualización de los botones de configuración

  const toggle = () => {
    setOpen(!open);
  };

  const activated = (arreglo) => {
    if (arreglo.length === 2) {
      setActive(1);
      return;
    } else {
      switch (arreglo[2]) {
        case 'users':
          setActive(2);
          break;
        case 'clientes':
          setActive(3);
          break;
        case 'cobranza':
          setActive(4);
          break;
        case 'cobradores':
          setActive(5);
          break;
        case 'configuracion':
          setActive(6);
          break;
        case 'grupo':
          setActive(7);
          break;
        case 'tipocliente':
          setActive(8);
          break;
        default:
          return;
      }
    }
  };


  useEffect(() => {
    const arreglo = pathname.split('/');
    activated(arreglo);
  }, [pathname]);

  return (
    <aside className={`${open ? style.Sidebar : style.Sidebar2} `}>

      <h1 className={`text-center ${style['sidebar-img']}`}>
        {
          //<img alt={logo} src={logo} />
        }
      </h1>

      <div className=''>
        <div className='' style={{ textAlign: "center", fontSize: '16px', marginTop: '20px' }}>
          <p className='fw-bold' style={{ color: 'white' }}>
            <TbUserHexagon style={{ marginRight: "10px" }} size={40} className='' />
            {usuario}
          </p>
        </div>

        <Link to='/panel'>
          <div className={`${style['sidebar-item']}  ${active === 1 && style.active} `}>
            <RiHome3Line size={sizeIcons} className='' />
            <label className={style['sidebar-label']}>Dashboard</label>
          </div>
        </Link>
        <Link to='/panel/users'>
          <div className={`${style['sidebar-item']}  ${active === 2 && style.active} `}>
            <FaUsersRectangle size={sizeIcons} className='' />
            <label className={style['sidebar-label']}>Usuarios</label>
          </div>
        </Link>
        <Link to='/panel/clientes'>
          <div className={`${style['sidebar-item']}  ${active === 3 && style.active} `}>
            <FaUsersViewfinder size={sizeIcons} className='' />
            <label className={style['sidebar-label']}>Clientes</label>
          </div>
        </Link>
        <Link to='/panel/cobranza'>
          <div className={`${style['sidebar-item']}  ${active === 4 && style.active} `}>
            <MdOutlineContactPage size={sizeIcons} className='' />
            <label className={style['sidebar-label']}>Cobranza</label>
          </div>
        </Link>
        <Link to='/panel/cobradores'>
          <div className={`${style['sidebar-item']}  ${active === 5 && style.active} `}>
            <LiaMotorcycleSolid size={sizeIcons} className='' />
            <label className={style['sidebar-label']}>Cobradores</label>
          </div>
        </Link>

        <div className={`${style['sidebar-itemGroup']}  ${active === 6 && style.active} `} onClick={() => setShowConfigButtons(!showConfigButtons)}>
          <div>
            <VscGear size={sizeIcons} className='' />
            <label className={style['sidebar-label']}>Configuracion</label>

            {showConfigButtons && (
              <div className="additional-buttons" style={{ display: 'flex', flexDirection: 'column', justifyContent: "center", borderTop: '1px solid #b5b5b5', width: '110%', marginTop: '15px' }}>
                <Link to='/panel/grupo'>
                  <div className={`${style['sidebar-itemConfig']}  ${active === 7 && style.active} `}>
                    <HiUserGroup size={'25'} className='' />
                    <label className={style['sidebar-label']}>Grupos</label>
                  </div>
                </Link>
                <Link to='/panel/tipocliente'>
                  <div className={`${style['sidebar-itemConfig']}  ${active === 8 && style.active} `}>
                    <FaUserTie size={'23'} className='' />
                    <label className={style['sidebar-label']}>Tipo cliente</label>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </div>

      </div>

      <div className={`${open ? style.sidebaruser : style.sidebaruser2} `}>
        <div className=''>
          <div className=''>
          </div>
          <div className='' style={{ textAlign: "center", display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: '15px' }}>
            <p className='fw-bold'>
              <Link
                to='/login'
                className='mt-5 cursor-pointer'
                style={{ fontWeight: 'bold', color: 'white', textDecoration: 'none' }}
                onClick={() => logout()}
              >
                <IoLogInOutline style={{ marginRight: '5px' }} size={sizeIcons} className='' />
                Cerrar Sesión
              </Link>
            </p>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;

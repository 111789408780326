import React, { useState } from 'react';
import './Modal_DUser.css';
import { FaTrash } from 'react-icons/fa';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';



const Modal = (props) => {
    const close = props.close;

    const [inputValues, setInputValues] = useState({
        nombre: '',
        parentesco: '',
        direccion: '',
        telefono: '',
        aval: false,
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
            [name]: name === 'aval' ? checked : value,
        }));
    };

    const handleAddRow = () => {
        // Validar que todos los campos estén completos
        if (!inputValues.nombre || !inputValues.parentesco || !inputValues.direccion || !inputValues.telefono) {
            console.log("error al agregar referencia")
            return;
        }

        var id = 1;
        // Verificar si hay elementos en props.data
        if (props.data.length > 0) {
            // Encontrar el número mayor en el campo id
            const maxId = props.data.reduce((max, item) => (item.id > max ? item.id : max), 0);
            // Sumar 1 al número mayor para obtener el nuevo id
            id = maxId + 1;
        }

        const buttonAccion = React.createElement('button', {
            id: 'btnExc', className: 'btn btn-sm btn-danger mx-1', type: 'button',
            onClick: () => {
                // Filtrar los datos para excluir el elemento que se está eliminando
                props.setDataReferencias((prevData) => {
                    // Filtrar los datos para excluir el elemento que se va a eliminar
                    const newData = prevData.filter(item => item.id !== id);

                    return newData;
                });
            },
        }, React.createElement(FaTrash, { size: 25, color: 'white' }));

        // Agregar los valores ingresados a la tabla de datos
        props.setDataReferencias((prevData) => [
            ...prevData,
            {
                nombre: inputValues.nombre,
                parentesco: inputValues.parentesco,
                direccion: inputValues.direccion,
                telefono: inputValues.telefono,
                aval: inputValues.aval,
                accion: buttonAccion,
                id: id
            },
        ]);

        // Vaciar los campos de entrada después de agregar
        setInputValues({
            nombre: '',
            parentesco: '',
            direccion: '',
            telefono: '',
            aval: false,
        });

        close();
    };

    const handleCancel = () => {
        // Vaciar los campos de entrada al cancelar
        setInputValues({
            nombre: '',
            parentesco: '',
            direccion: '',
            telefono: '',
            aval: false,
        });

        close();
    };

    return (
        <div className="modal-containerReferencias" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-background" onClick={close}></div>
            <div className="modal-wrapperReferencia">
                <div className="headerFormulario">
                    <h3>Datos de la referencia</h3>
                </div>
                <div className='formularioAgregarReferencia'>
                    <div className="form-group">
                        <label htmlFor="nombre">Nombre:</label><br />
                        <input
                            type="text"
                            name="nombre"
                            value={inputValues.nombre}
                            onChange={handleChange}
                            placeholder="Nombre"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="parentesco">Parentesco:</label><br />
                        <input
                            type="text"
                            name="parentesco"
                            value={inputValues.parentesco}
                            onChange={handleChange}
                            placeholder="Parentesco"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="direccion">Dirección:</label><br />
                        <input
                            type="text"
                            name="direccion"
                            value={inputValues.direccion}
                            onChange={handleChange}
                            placeholder="Dirección"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="telefono">Teléfono:</label><br />
                        <input
                            type="text"
                            name="telefono"
                            value={inputValues.telefono}
                            onChange={handleChange}
                            placeholder="Teléfono"
                        />
                    </div>
                </div>
                <div className="input-modal campo-aval">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p className='p-modal' style={{ marginLeft: '20px', marginRight: '20px', marginTop: 'revert' }}>¿El cliente es aval?:</p>
                    </div>
                    <div style={{ display: 'flex' /*width: '40%'*/ }}>
                        <FormControlLabel
                            control={<Switch checked={inputValues.aval} onChange={handleChange} name="aval" />}
                        />
                    </div>
                </div>
                <div className="modal-botones">
                    <div className="divbtn">
                        <button className="btn btn-success mx-1 boton-modal" type='button' onClick={handleAddRow}>Agregar</button>
                        <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}> Cancel </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;

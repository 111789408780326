import jwt_decode from "jwt-decode";


export const storeToken = ( token ) => {
   sessionStorage.setItem('token', token);
}

export const getVerifiedToken = () => {
   try {
     const token = getToken();
     if (token) {
       const decoded = jwt_decode( token );
       return { token, decoded };
     }
     return null;
   } catch (error) {
     logout();
 
     return null;
   }
 }

export const getToken = ( decode = false ) => {
   const token = sessionStorage.getItem('token');
   if( decode ){
     const decoded = jwt_decode(token);
     return decoded;
   }
   return token;
}

export const logout = () => {
   sessionStorage.clear();
}
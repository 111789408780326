import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import style from "./PasswordChangeSuccessPage.module.sass"


const PasswordChangeSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/login');
    }, 4000);

    return () => {
      console.log('');
    };
  }, [navigate]);

  return (
    <div className={`${style.PasswordChangeSuccessPage}`}>
      <div className={style.back} />
      <div className={style.form}>
        <div className='mt-2'>
          <label className='flex justify-center text-3xl text-rose-900 fw-bolder'>
            Password recovery
          </label>
        </div>

        <div className='text-center mt-4 text-gray-700 text-lg'>
          <label>Password has been changed successfully.</label>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-40 h-40 text-rose-900 mt-2'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M14.563 9.75a12.014 12.014 0 00-3.427 5.136L9 12.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z'
            />
          </svg>
        </div>

        <div className='text-center mt-2 mb-2 text-rose-500 fw-bolder'>
          <label>You'll be redirected to log in...</label>
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeSuccessPage
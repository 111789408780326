import React, { useState, useEffect } from 'react';
import style from './HistorialPage.module.sass';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineContactPage } from 'react-icons/md';
import axiosConfig from '../../../utils/axiosConfig';
import { Pagination } from '../../../components/pagination/Pagination';

import { MdArrowBackIosNew } from 'react-icons/md';
import { FiFilePlus } from "react-icons/fi";

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const HistorialPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Obtiene el usuario_id de la URL
  const [token, setToken] = useState(null);
  const [lUsers, setLUsers] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const mensajeVacio = '';
  const [refreshUsers, setRefreshUsers] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [cobradores, setCobradores] = useState({}); // Objeto para almacenar nombres y apellidos de cobradores

  const backlUser = () => {
    navigate("/panel/cobranza");
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
    // Llamar a la función getHistorial al cargar la página
    getHistorial(id);
    getUsuarios();
  }, [id]); // El useEffect se ejecutará cada vez que cambie el id

  useEffect(() => {
    if (refreshUsers) {
      getHistorial(id);
    }
    setRefreshUsers(false);
  }, [refreshUsers, id]); // Asegúrate de que se vuelva a cargar al cambiar refreshUsers o id

  const getHistorial = (userId) => {
    axiosConfig({
      method: 'GET',
      url: `/convenios/${userId}/historial/`, // Utiliza el userId recibido
      params: { ...filter },
    })
      .then((result) => {
        console.log("Carga de datos: ", result);
        setLUsers(result.rows);
        setPageCount(Math.ceil(result.count / 10));
      })
      .catch((err) => console.log(err));
  };

  const handlePageClick = (event) => {
    setFilter(() => {
      return { ...filter, page: event.selected + 1 };
    });
    setRefreshUsers(true);
  };

  const getUsuarios = () => {
    axiosConfig({
      method: 'GET',
      url: '/usuarios',
      params: { ...filter },
    })
      .then((result) => {
        console.log("Carga de datos usuarios: ", result);

        // Verifica si result.rows existe antes de iterar sobre él
        if (result.rows && Array.isArray(result.rows)) {
          const newCobradores = {};
          result.rows.forEach(usuario => {
            newCobradores[usuario.usuario_id] = {
              nombre: usuario.nombre,
              apellido: usuario.apellido
            };
          });

          // Actualiza el estado de cobradores con el nuevo objeto
          setCobradores(newCobradores);
        } else {
          console.log("No se encontraron usuarios en la respuesta");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={`${style.ConveniosPage}`}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <MdOutlineContactPage
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ 'mix-blend-mode': 'difference' }}>Historial</span>
        </h3>
      </div>

      <div className={`${style.boxHistorial} bg-white`}>
        <div className={`${style.boxBarraHistorial} fw-normal p-2 border-secondary`}>
          <h3 className='h3 fw-bold' style={{ fontSize: 20 + 'px' }}>
            <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer', marginRight: '10px' }} />
          </h3>
        </div>
      </div>

      <div className={`${style.table} table-responsive bg-white`}>
        <table className='table table-borderless border-bottom'>
          <thead>
            <tr className='text-start border-bottom'>
              <th className='text-center'>Cobrado por</th>
              <th className='text-center'>Fecha</th>
              <th className='text-center'>Tipo de pago</th>
              <th className='text-center'>Cantidad recibida</th>
              <th className='text-center'>Abono</th>
              <th className='text-center'>Cambio</th>
              <th className='text-center'>Saldo restante</th>
            </tr>
          </thead>
          <tbody>
            {lUsers.length > 0 &&
              lUsers.map((u) => (
                <tr className='text-start border-bottom align-middle' key={u.historial_id}>
                  <td className='text-center'>{u.detalle_usuario_cobrador?.nombre} {u.detalle_usuario_cobrador?.apellido}</td>
                  <td className='text-center'>{u.fecha ? new Date(u.fecha).toLocaleDateString('es-ES', { timeZone: 'UTC' }) : ''}</td>
                  <td className='text-center'>{u.metodo_pago ? u.metodo_pago : 'sin metodo de pago'}</td>
                  <td className='text-center' >${u.cantidad_otorgada ? u.cantidad_otorgada : 0}</td>
                  <td className='text-center' style={(u.abono < u.convenio.cantidad_abono) ? { color: 'red', fontWeight: 'bold' } : {}}>${u.abono}</td>
                  <td className='text-center'>${u.reciduo ? u.reciduo : 0}</td>
                  <td className='text-center'>${u.saldo}</td>
                </tr>
              ))}
            {lUsers.length === 0 && (
              <tr>
                <td colSpan={7} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                  <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>
    </div>
  );
};

export default HistorialPage;

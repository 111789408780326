import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Importa axios
import style from "./CreateConveniosPage.module.sass";
import { MdArrowBackIosNew } from 'react-icons/md';
import { MdOutlineContactPage, } from 'react-icons/md';
import { FiFilePlus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { useForm, Controller } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import DataTable from '../../../components/modal_convenios/DataTable';
import Modal from '../../../components/modal_convenios/modal_agregar/Modal_Convenios';
import Select from 'react-select';
import { Stack } from '@mui/material'; // Si estás usando Material-UI
import { Switch as AntSwitch } from 'antd'; // Si estás usando Ant Design

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const CreateConveniosPage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const backlUser = () => {
    navigate("/panel/cobranza");
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm();

  //DataTable
  const mensajeVacio = '';

  const [dataReferencia, setDataReferencias] = useState([]);

  const [recalcular, setRecalcular] = useState(false);

  useEffect(() => {
    console.log(dataReferencia);
  }, [dataReferencia]);

  const [modalShow, setModalShow] = useState(false);
  const closeModalHandler = () => {
    setModalShow(false);
  };

  const openModalHandler = (e) => {
    setModalShow(true);
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Codigo', accessor: 'codigo' },
      { Header: 'Modelo', accessor: 'modelo' },
      { Header: 'Color', accessor: 'color' },
      { Header: 'Precio', accessor: 'precio' },
      { Header: 'Accion', accessor: 'accion' },
    ],
    []
  );

  //Obtener Clientes
  const [clientesLista, setClientes] = useState([]);
  const [filterTC, setFilterTC] = useState(initialFilter);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterTC }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}clientes`, headers);
        console.log("Respuesta completa de la API:", response.data);
        setClientes(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los clientesLista:", error);
      }
    };

    if (token) {
      fetchClientes();
    }
  }, [token]);

  //Obtener Clientes
  const [cobradoresLista, setCobradores] = useState([]);
  const [filterCobradores, setFilterCobradores] = useState(initialFilter);

  useEffect(() => {
    const fetchCobradores = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterCobradores }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}cobrador`, headers);
        console.log("Respuesta completa de la API Cobradores:", response.data);
        setCobradores(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los cobradores:", error);
      }
    };

    if (token) {
      fetchCobradores();
    }
  }, [token]);

  const [switchValue, setSwitchValue] = useState(true);
  const [switchValueText, setSwitchValueText] = useState('Casa');
  const handleSwitchChange = (checked) => {

    if (checked) {
      setSwitchValueText('Casa');
    }
    else {
      setSwitchValueText('Trabajo');
    }
    setSwitchValue(checked);
    console.log('Switch value:', checked); // Puedes usar el valor como necesites
  };


  const [tipoClientes, setTipoClientes] = useState([]);

  useEffect(() => {
    const fetchTipoClientes = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterTC }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}tipocliente`, headers);
        console.log("Respuesta completa de la API:", response.data);
        setTipoClientes(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los tipoClientes:", error);
      }
    };

    if (token) {
      fetchTipoClientes();
    }
  }, [token]);

  // Función para calcular la suma total de los precios de los convenios
  const [subtotal, setSubtotal] = useState(0); // Estado para el subtotal
  const [iva, setIVA] = useState(0); // Estado para el IVA
  const [total, setTotal] = useState(0); // Estado para el total
  const [enganche, setEnganche] = useState(0); // Estado para el enganche
  const [saldoRestante, setSaldoRestante] = useState(0); // Estado para el saldo restante
  const [tipoAbono, setTipoAbono] = useState(''); // Estado para el tipo de abono
  const [fechaInicial, setFechaInicial] = useState(''); // Estado para la fecha inicial
  const [fechaSiguiente, setFechaSiguiente] = useState(''); // Estado para la fecha del siguiente abono


  useEffect(() => {
    // Calcula el subtotal sumando los precios de todos los convenios
    const calcularSubtotal = () => {
      const sub = dataReferencia.reduce((total, convenio) => total + Number(convenio.precio), 0);
      return sub;
    };

    // Calcula el IVA multiplicando el subtotal por 0.16
    const calcularIVA = (subtotal) => {
      //const impuesto = subtotal * 0.16;
      const impuesto = 0;
      return impuesto;
    };

    // Calcula el total sumando el subtotal y el IVA
    const calcularTotal = (subtotal, iva) => {
      const tot = subtotal + iva;
      return tot;
    };

    // Calcula el saldo restante restando el enganche del total
    const calcularSaldoRestante = (total, enganche) => {
      const saldo = total - enganche;
      return saldo;
    };

    // Actualiza los estados de subtotal, IVA y total
    const sub = calcularSubtotal();
    const imp = calcularIVA(sub);
    const tot = calcularTotal(sub, imp);
    const saldo = calcularSaldoRestante(tot, enganche);
    setSubtotal(sub);
    setIVA(imp);
    setTotal(tot);
    setSaldoRestante(saldo);
  }, [dataReferencia, enganche]); // Ejecuta el efecto cuando cambia el array de convenios

  // Función para manejar el cambio en el campo de enganche
  const handleEngancheChange = (e) => {
    const { value } = e.target;
    setEnganche(Number(value)); // Convierte el valor a número y actualiza el estado del enganche
  };

  // Función para manejar el cambio en el campo de saldo restante
  const handleSaldoRestanteChange = (e) => {
    const { value } = e.target;
    setSaldoRestante(Number(value));
  };

  // Función para manejar el cambio en el campo tipo_abono
  const handleTipoAbonoChange = (e) => {
    const { value } = e.target;
    setTipoAbono(value); // Actualiza el estado del tipo de abono

    // Calcula la fecha del siguiente abono dependiendo del tipo de abono y la fecha inicial
    calculateFechaSiguiente(value, fechaInicial);
  };

  // Función para manejar el cambio en la fecha inicial
  const handleFechaInicialChange = (e) => {
    const nuevaFechaInicial = e.target.value;
    setFechaInicial(nuevaFechaInicial); // Actualiza el estado de la fecha inicial

    // Calcula la fecha del siguiente abono dependiendo del tipo de abono y la nueva fecha inicial
    calculateFechaSiguiente(tipoAbono, nuevaFechaInicial);
  };

  const handleFechaSiguienteChange = (e) => {
    const { value } = e.target;
    setFechaSiguiente(value);
  };

  // Función para calcular la fecha del siguiente abono
  const calculateFechaSiguiente = (tipoAbono, fechaInicial) => {
    let dias = tipoAbono === 'semanal' ? 7 : 15;
    if (fechaInicial) {
      const fechaInicialObj = new Date(fechaInicial);
      if (!isNaN(fechaInicialObj.getTime())) {
        const fechaSiguienteObj = new Date(fechaInicialObj.getTime() + (dias * 24 * 60 * 60 * 1000));
        setFechaSiguiente(fechaSiguienteObj.toISOString().split('T')[0]);
      } else {
        setFechaSiguiente('');
      }
    } else {
      setFechaSiguiente('');
    }
  };

  useEffect(() => {
    // Define las reglas de validación utilizando la librería react-hook-form
    register('enganche', { required: true });
    register('tipo_abono', { required: true });
    register('cantidad_abono', { required: true });
    //register('saldo_restante', { required: true });
    register('fecha_primer_abono', { required: true });
    //register('fecha_siguiente_abono', { required: true });
  }, [register]);

  const onSubmit = async (data) => {
    try {
      // Añade los datos adicionales necesarios al objeto data antes de enviar la solicitud
      data.subtotal = subtotal;
      data.iva = iva;
      data.total = total;

      // Calcula la fecha del siguiente abono si está vacía
      if (!data.fecha_siguiente_abono) {
        const tipoAbono = data.tipo_abono;
        const fechaInicial = data.fecha_primer_abono;
        calculateFechaSiguiente(tipoAbono, fechaInicial);
        data.fecha_siguiente_abono = fechaSiguiente;
      }

      const tipoCliente = watch("tipocliente_id");
      data.tipocliente_id = parseInt(tipoCliente);

      data.direccionCobro = switchValue;

      const headers = {
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded",
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };

      let requestData = {
        ...data,
        subtotal: subtotal,
        iva: iva,
        total: total,
        saldo_restante: saldoRestante,
        convenio_movimientos: dataReferencia
      };

      // Realiza la solicitud POST a la API
      console.log("data", data);
      console.log("header", headers);
      await axios.post(process.env.REACT_APP_API_URL + 'convenios', requestData, headers);
      NotificationManager.success('Cobranza agregada exitosamente');
      // Redirige a la página deseada
      navigate('/panel/cobranza');
    } catch (error) {
      console.error('Error al agregar la cobranza:', error);
      NotificationManager.error('Error al agregar la cobranza');
    }
  };

  // ID del tipo de cliente "Optica Vision Natural"
  const defaultTipoClienteId = tipoClientes.find(tipocliente => tipocliente.nombre === 'Optica Vision Natural')?.tipocliente_id;

  // Establecer el valor por defecto al montar el componente
  useEffect(() => {
    if (defaultTipoClienteId) {
      setValue('tipocliente_id', defaultTipoClienteId);
    }
  }, [defaultTipoClienteId, setValue]);
  
  return (
    <div className={`${style.CreateConveniosPage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <MdOutlineContactPage
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ mixBlendMode: 'difference' }}>Agregar una nueva cobranza</span>
        </h3>
      </div>

      {/* Agrega el formulario para ingresar los datos del usuario */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${style.box} bg-white`}>
          <div className={`${style.boxBarra} fw-normal p-2 border-secondary`}>
            <h3 className='h3 fw-bold' style={{ fontSize: 20 + 'px' }}>
              <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer', marginRight: '10px' }} />
            </h3>
            <button type="submit">
              <FiFilePlus
                color={'white'}
                size={'35'}
              />
            </button>
          </div>
        </div>

        <div className={`${style.formularioCliente} table-responsive bg-white`} style={{ marginTop: '40px', marginBottom: '50px' }}>
          <div className={`${style.referencias}`}>
            <div className={`${style.headerProducto} `}>
              <h3>Compra</h3>
            </div>
            <div className={`${style.referenciasBoton}`}>
              <button className="botonReferencias" type='button' onClick={openModalHandler}>
                <FaPlus
                  color={'white'}
                  size={'30'}
                /></button>
            </div>
            <div className={`${style.dataTable} `}>
              <DataTable columns={columns} data={dataReferencia} />
              {dataReferencia.length === 0 && (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                    <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                  </td>
                </tr>
              )}
            </div>
            <div className={`${style.compraTotal}`}>
              {/*<div className={`${style.divPrecios}`}>
                <label htmlFor="subtotal">Subtotal:</label>
                <input id="subtotal" {...register("subtotal")} value={subtotal} placeholder="Subtotal de la compra" disabled />
              </div>
              <div className={`${style.divPrecios}`}>
                <label htmlFor="iva">IVA:</label>
                <input id="iva" {...register("iva")} value={iva} placeholder="IVA de la compra" disabled />
              </div>*/}
              <div className={`${style.divPrecios}`}>
                <label htmlFor="total">Total:</label>
                <input id="total" {...register("total")} value={total} placeholder="Total de la compra" disabled />
              </div>
            </div>
          </div>
        </div>

        <div className={`${style.formularioUsuarios} `}>
          <div className={`${style.headerFormulario} `}>
            <div className={`${style.headerProducto} `}>
              <h3>Datos de cobranza</h3>
            </div>
          </div>
          <div className={`${style.formulario} `}>
            {/*Select para el Tipo de cliente*/}
            <div className={style.form_group}>
              <label htmlFor="cliente_id">Cliente:</label>
              <Controller
                name="cliente_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={clientesLista.map(cliente => ({
                      value: cliente.cliente_id,
                      label: `${cliente.nombre} ${cliente.apellido}`
                    }))}
                    value={clientesLista
                      .map(cliente => ({
                        value: cliente.cliente_id,
                        label: `${cliente.nombre} ${cliente.apellido}`
                      }))
                      .find(option => option.value === field.value) || null}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                    placeholder="Cliente"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '50px',
                        minHeight: '50px',
                        width: '95%',
                        borderColor: state.isFocused ? 'black' : 'gray',
                        boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                        '&:hover': {
                          borderColor: 'gray'
                        }
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        height: '50px',
                        minHeight: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: '95%'
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: '88%'
                      })
                    }}
                  />
                )}
              />
            </div>

            <div class="form-group">
              <label htmlFor="enganche">Enganche:</label><br />
              <input id="enganche" {...register("enganche")} value={enganche} onChange={handleEngancheChange} placeholder="" />
              {errors.enganche && <p style={{ color: 'red' }}>El enganche es requerido</p>}
            </div>

            <div className="form-group">
              <label htmlFor="saldo_restante">Saldo restante:</label>
              <br />
              <input
                id="saldo_restante"
                {...register("saldo_restante")}
                value={saldoRestante}
                onChange={handleSaldoRestanteChange}
                placeholder=""
                disabled
              />
              {errors.saldo_restante && <p style={{ color: 'red' }}>El saldo restante es requerido</p>}
            </div>

            <div className="form-group">
              <label htmlFor="tipo_abono">Tipo de abono:</label><br />
              <select id="tipo_abono" {...register("tipo_abono", { required: false })} value={tipoAbono} onChange={handleTipoAbonoChange}>
                <option value="" disabled>Selecciona un tipo de abono.</option>
                <option value="semanal">Semanal</option>
                <option value="quincenal">Quincenal</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="fecha_primer_abono">Fecha Inicial (primer abono):</label><br />
              <input id="fecha_primer_abono" type="date" {...register("fecha_primer_abono")} value={fechaInicial} onChange={handleFechaInicialChange} placeholder="Fecha de nacimiento del cliente" />
              {errors.fecha_primer_abono && <p style={{ color: 'red' }}>La fecha de nacimiento es requerida</p>}
            </div>

            <div className="form-group">
              <label htmlFor="fecha_siguiente_abono">Fecha del siguiente abono:</label><br />
              <input id="fecha_siguiente_abono" type="date" {...register("fecha_siguiente_abono")} value={fechaSiguiente} onChange={handleFechaSiguienteChange} placeholder="Fecha del siguiente abono" />
              {errors.fecha_siguiente_abono && <p style={{ color: 'red' }}>La fecha del siguiente abono es requerida</p>}
            </div>

            <div class="form-group">
              <label htmlFor="cantidad_abono">Cantidad de abono:</label><br />
              <input id="cantidad_abono" {...register("cantidad_abono")} placeholder="Cantidad a abonar (Pagos)." />
              {errors.cantidad_abono && <p style={{ color: 'red' }}>La cantidad del abono es requerida</p>}
            </div>

            {/*Select para el Tipo de cliente*/}
            <div className={style.form_group}>
              <label htmlFor="tipocliente_id">Tipo de cliente:</label>
              <Controller
                name="tipocliente_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={tipoClientes.map(tipocliente => ({
                      value: tipocliente.tipocliente_id,
                      label: tipocliente.nombre
                    }))}
                    value={tipoClientes
                      .map(tipocliente => ({
                        value: tipocliente.tipocliente_id,
                        label: tipocliente.nombre
                      }))
                      .find(option => option.value === field.value) || null}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                    placeholder="Seleccione cliente"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '50px',
                        minHeight: '50px',
                        width: '95%',
                        borderColor: state.isFocused ? 'black' : 'gray',
                        boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                        '&:hover': {
                          borderColor: 'gray'
                        }
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        height: '50px',
                        minHeight: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: '95%'
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: '88%'
                      })
                    }}
                  />
                )}
              />
            </div>

            {/*Select para asignar al cobrador*/}
            <div className={style.form_group}>
              <label htmlFor="cobrador">Asignar cobrador:</label>
              <Controller
                name="usuario_cobro"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={cobradoresLista.map(cobrador => ({
                      value: cobrador.usuario_id,
                      label: cobrador.nombre
                    }))}
                    value={cobradoresLista
                      .map(cobrador => ({
                        value: cobrador.usuario_id,
                        label: cobrador.nombre
                      }))
                      .find(option => option.value === field.value) || null}
                    onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                    placeholder="Cobrador"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: '50px',
                        minHeight: '50px',
                        width: '95%',
                        borderColor: state.isFocused ? 'black' : 'gray', // Cambia los colores según tus preferencias
                        boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                        '&:hover': {
                          borderColor: 'gray' // Color del borde al pasar el ratón
                        }
                      }),
                      valueContainer: (baseStyles) => ({
                        ...baseStyles,
                        height: '50px',
                        minHeight: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: '95%'
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        display: 'flex',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: '88%'
                      })
                    }}
                  />
                )}
              />
            </div>

            <div className="form-group">
              <label htmlFor="domicilio_cobro">Domicilio de cobro: {switchValueText}</label><br />
              <Stack direction="row" spacing={2} style={{ paddingTop: '15px' }}>
                <div>Trabajo</div>
                <AntSwitch checked={switchValue} onChange={handleSwitchChange} />
                <div>Casa</div>
              </Stack>

            </div>
          </div>

          <div className={`${style.formularioNota} `}>
            <div className="form-group">
              <label htmlFor="nota">Notas:</label><br />
              <textarea id="nota" type="text" {...register("nota")} placeholder="Agrega una nota para dar mas detalles" />
              {errors.nota && <p style={{ color: 'red' }}>La fecha de nacimiento es requerida</p>}
            </div>
          </div>
        </div>
      </form>
      <Modal
        className='modal'
        show={modalShow}
        setModalShow={setModalShow}
        close={closeModalHandler}
        setDataReferencias={setDataReferencias}
        data={dataReferencia}
        recalcular={setRecalcular}
      ></Modal>
    </div>
  );
};

export default CreateConveniosPage;

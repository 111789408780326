import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./ClientesPage.module.sass";
import axiosConfig from '../../../utils/axiosConfig';
import axios from "axios"; // Importa axios
import { FaUsersRectangle } from "react-icons/fa6";
import { FaTrash, FaPencilAlt, FaCheck } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { Pagination } from '../../../components/pagination/Pagination';
import { Filter } from '../../../components/filter/Filter';
import { NotificationManager } from "react-notifications";
import Swal from 'sweetalert2'; // Importa SweetAlert

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const ClientesPage = () => {
  const navigate = useNavigate();

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const [lClientes, setLClientes] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  const [searchBtn, setSearchBtn] = useState(false);
  const [activeFilter, setActiveFilter] = useState(true);

  const [filter, setFilter] = useState(initialFilter);
  const mensajeVacio = '';

  const [refreshClientes, setRefreshClientes] = useState(false);

  const [detailCliente, setDetailClientes] = useState({});

  useEffect(() => {
    if (refreshClientes) {
      getClientes();
    }
    setRefreshClientes(false);
  }, [refreshClientes]);

  useEffect(() => {
    setFilter(() => {
      return { ...filter, status: activeFilter };
    });
    setRefreshClientes(true);
  }, [activeFilter]);

  useEffect(() => {
    if (searchBtn && searchInput != null) {
      setFilter(() => {
        return { ...filter, search: searchInput };
      });
      setRefreshClientes(true);
      setSearchBtn(false);
    }
  }, [searchBtn]);

  const [pageCount, setPageCount] = useState(0);

  const getClientes = () => {
    axiosConfig({
      method: 'GET',
      url: '/clientes',
      params: { ...filter },
    })
      .then((result) => {
        console.log("Carga de datos: ", result);

        setLClientes(result.rows);
        setPageCount(Math.ceil(result.count / 10));
      })
      .catch((err) => console.log(err));
  };

  const handlePageClick = (event) => {
    setFilter(() => {
      return { ...filter, page: event.selected + 1 };
    });
    setRefreshClientes(true);
  };

  const closeModalHandler = () => {
    setModalShow(false);
  };

  const openModalHandler = (e) => {
    console.log('Datos de la tabla: ', e);
    setDetailClientes(e);
    setModalShow(true);
  };

  const onchangeOptions = (e) => {
    axiosConfig({
      method: 'put',
      url: `/clientes/activo/${e.user_id}`,
    })
      .then(() => {
        NotificationManager.success(
          !e.active ? 'Activated with success' : 'Activated with success',
          'Success!',
          2500
        );
        setRefreshClientes(true);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.success('Could not change state', 'Error!');
      })
      .finally(() => {
        closeModalHandler();
        setRefreshClientes(true);
      });
  };

  const cambioActivo = async (clienteId, data, actionType) => {
    try {
      let confirmationMessage1 = '';
      let confirmationMessage2 = ''; // Inicializa el mensaje de confirmación

      // Define el mensaje de confirmación en función del tipo de acción
      if (actionType === 'desactivar') {
        confirmationMessage1 = 'Desactivar Cliente';
        confirmationMessage2 = '¿Estás seguro de que deseas desactivarlo?';
      } else if (actionType === 'activar') {
        confirmationMessage1 = 'Activar Cliente';
        confirmationMessage2 = '¿Estás seguro de que deseas activarlo?';
      }
      
      const confirmed = await Swal.fire({ // Utiliza Swal.fire para mostrar SweetAlert
        title: confirmationMessage1,
        text: confirmationMessage2,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, realizar acción',
        cancelButtonText: 'Cancelar',
      });

      if (!confirmed.isConfirmed) return; // Si el usuario cancela, no hacer nada

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };
      // Realiza la solicitud PUT a la API para actualizar el estado del usuario
      await axios.put(`${process.env.REACT_APP_API_URL}clientes/estatus/${clienteId}`, data, headers);

      getClientes();

      NotificationManager.success('Estado del cliente actualizado exitosamente');

      // Redirige a la página deseada
      //navigate('/panel/users');
    } catch (error) {
      console.error('Error al actualizar el estado del cliente:', error);
      NotificationManager.error('Error al actualizar el estado del cliente');
    }
  };


  return (
    <div className={`${style.ClientesPage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <FaUsersRectangle
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ 'mix-blend-mode': 'difference' }}>Clientes</span>
        </h3>
      </div>


      <Filter
        setActiveFilter={setActiveFilter}
        setSearchInput={setSearchInput}
        setSearchBtn={setSearchBtn}
        setModulo='clientes'
      />

      <div className={`${style.table} table-responsive bg-white`}>
        <table className='table table-borderless border-bottom'>
          <thead>
            <tr className='text-start border-bottom'>
              <th className='text-center'>Nombre</th>
              <th className='text-center'>Telefono</th>
              <th className='text-center'>Direccion</th>
              <th className='text-center'>Activo</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lClientes.length > 0 &&
              lClientes.map((u) => (
                <tr className='text-start border-bottom align-middle' key={u.cliente_id}>
                  <td className='text-center'>{u.nombre + ' ' + u.apellido}</td>
                  <td className='text-center'>{u.telefono}</td>
                  <td className={`text-center ${style['direccion-width']}`}>{u.direccion}</td>
                  <td className='text-center'>
                    {u.activo ? (
                      <span className='badge bg-success rounded-pill'>Activo</span>
                    ) : (
                      <span className='badge bg-danger rounded-pill'>Inactivo</span>
                    )}
                  </td>
                  <td className={`text-center ${style['accionesWidth']}`}>
                    <button
                      id='btnEdit'
                      className='btn btn-sm btn-warning mx-1'
                      onClick={() => navigate(`/panel/clientes/updateCliente/${u.cliente_id}`)}
                    >
                      <FaPencilAlt size={25} color='white' />
                    </button>
                    {u.activo === true ? (
                      <button
                        id='btnExc'
                        className='btn btn-sm btn-danger mx-1'
                        onClick={() => {
                          cambioActivo(u.cliente_id, { activo: false }, 'desactivar')
                          console.log(u.cliente_id);
                          openModalHandler(u);
                        }}
                      >
                        <FaTrash size={25} color='white' />
                      </button>
                    ) : (
                      <button
                        id='btnExc'
                        className='btn btn-sm btn-success mx-1'
                        onClick={() => {
                          cambioActivo(u.cliente_id, { activo: true }, 'activar')
                          console.log(u.cliente_id);
                          openModalHandler(u);
                        }}
                      >
                        <FaCheck size={25} color='white' />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            {lClientes.length === 0 && (
              <tr>
                <td colSpan={6} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                  <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>

    </div>
  );






};

export default ClientesPage;

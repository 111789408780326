import React, { useState, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import '../modal_cobrador/Modal_cobrador.css';

import axios from "axios"; // Importa axios

const initialFilter = {
    search: null,
    status: true,
    order: 'desc',
    limit: 10,
    page: 1,
};
const ModalCobradores = (props) => {
    const close = props.close;

    const [inputValues, setInputValues] = useState({
        cobrador: 0,
    });

    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
    }, [])

    const [cobradores, setCobradores] = useState([]);
    const [filter, setFilter] = useState(initialFilter);

    useEffect(() => {
        const fetchGrupos = async () => {
            try {
                const headers = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                    params: { ...filter }
                };

                const response = await axios.get(`${process.env.REACT_APP_API_URL}cobrador`, headers);

                console.error("datos cobradores:", response.data.rows);
                setCobradores(response.data.rows)
            } catch (error) {
                console.error("Error al obtener los grupos:", error);
            }
        };
        fetchGrupos();
    }, [token]);

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };


    const handleCancel = () => {
        // Vaciar los campos de entrada al cancelar
        setInputValues({
            abono: 0,
            total: 0,
            saldo: 0,
            fecha: '',
            usuario_cobrador: ''
        });

        close();
    };

    return (
        <div className="modal-container" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-background" onClick={close}></div>
            <div className="modal-wrapper">
                <div className="headerFormulario">
                    <h3>Asignar Cobrador</h3>
                </div>
                <div className="formularioCobrador">
                    <div className="form-group">
                        <label htmlFor="grupo">Cobradores:</label>
                        <br />
                        <select className='selectCobradores' id="grupo">
                            <option value="">Selecciona un cobrador</option>
                            {cobradores.length > 0 ? (
                                cobradores.map((cobrador) => (
                                    <option key={cobrador.usuario_id} value={cobrador.usuario_id}>
                                        {cobrador.nombre + ' ' + cobrador.apellido}
                                    </option>
                                ))
                            ) : (
                                <option disabled>No hay cobradores disponibles</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="modal-botones">
                    <div className="divbtn">
                        <button className="btn btn-success mx-1 boton-modal" type='button'>Abonar</button>
                        <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ModalCobradores;

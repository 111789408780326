import React from 'react'
import style from './Box.module.sass'
import PropTypes from 'prop-types';

export const Box = ( props ) => {
   return (
      <div className={ `  ${ style.Box }  ${ props.className }` } style={props.style}  onClick={ props.onClick } >
         { props.children }
      </div>
   )
}

Box.propTypes = {
   className: PropTypes.string,
   onClick: PropTypes.func,
   style: PropTypes.object
}

Box.defaultProps = {
   className: "",
   onClick: (e) => e,
   style: {}
}
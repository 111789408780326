import React, { useState, useEffect } from "react";
import style from "./Filter.module.sass";
import { useNavigate } from 'react-router-dom';

import { BiSearch } from "react-icons/bi";
import { BiUserPlus } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { FiFilePlus } from "react-icons/fi";
import { Box } from "../box/Box";
import ModalGrupo from '../modal_grupo/modal_agregar/Modal_Grupo';
import ModalTipoClientes from '../modal_tc/modal_agregar/Modal_TipoCliente';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';

import axiosConfig from '../../utils/axiosConfig';

export const Filter = (props) => {

  const {
    control,
    formState,
  } = useForm();

  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  const onchangeStatus = (e) => {
    props.setActiveFilter(e.target.value);
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    props.setSearchInput(event.target.value);
  };

  const handleClick = (event) => {
    event.preventDefault();
    props.setSearchBtn(true);
  };

  // CODIGO PARA MODAL AGREGAR GRUPO
  const [dataReferencia, setDataReferencias] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const closeModalHandler = () => {
    setModalShow(false);
  };

  const openModalHandler = (e) => {
    setModalShow(true);
  };

  // CODIGO PARA MODAL EDITAR GRUPO
  const [dataReferenciaTC, setDataReferenciasTC] = useState([]);
  const [modalShowTC, setModalShowTC] = useState(false);
  const closeModalHandlerTC = () => {
    setModalShowTC(false);
  };

  const openModalHandlerTC = (e) => {
    setModalShowTC(true);
  };

  // CODIGO PARA MODAL CAJA CHICA
  const [dataReferenciaCCH, setDataReferenciasCCH] = useState([]);
  const [modalShowCH, setModalShowCCH] = useState(false);
  const closeModalHandlerCCH = () => {
    setModalShowCCH(false);
  };

  const openModalHandlerCCH = (e) => {
    setModalShowCCH(true);
  };


  const [lCobradores, setLCobradores] = useState([]);
  useEffect(() => {
    if (props.setModulo === 'cobranza') {
      axiosConfig({
        method: 'GET',
        url: '/cobrador',
        params: {
          search: null,
          status: true,
          order: 'desc'
        },
      })
        .then((result) => {
          setLCobradores(result.rows);
        })
        .catch((err) => console.log(err));
    }
  }, [props.setModulo]);

  const onchangeCobrador = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : null;
    props.setFilter({ ...props.filter, cobrador: value });
    props.setRefreshUsers(true);
  };

  return (
    <Box className={`my-3 ${style.Filter} d-flex align-items-center`}>
      <div className="w-25">
        <input
          className="form-control"
          type="text"
          placeholder="Search"
          id="search"
          onChange={handleChange}
          value={search}
        />
      </div>

      <div className="ms-3 me-5">
        <button
          type="button"
          className={`btn d-flex align-items-center ${style['filtroSearch']}`}
          onClick={handleClick}
        >
          <BiSearch size={20} />
        </button>
      </div>

      <div className="me-3" style={{ width: '150px !important' }}>
        <select
          className="form-select"
          id="statusUser"
          name="statusUser"
          style={{ width: '150px' }}
          onChange={onchangeStatus}
        >
          {props.setModulo === 'cobranza' ? (
            <>
              <option value="En Proceso">En proceso</option>
              <option value="Finalizada">Finalizado</option>
            </>
          ) : (
            <>
              <option value="true">Activo</option>
              <option value="false">Inactivo</option>
            </>
          )}
        </select>
      </div>
      {props.setModulo === 'cobranza' ?
        <div className="me-3 w-25">
          <Controller
            name="cobrador_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={[
                  { value: 0, label: 'Seleccione Cobrador' },
                  ...lCobradores.map(cobrador => ({
                    value: cobrador.usuario_id,
                    label: cobrador.nombre
                  }))
                ]}
                value={[
                  { value: 0, label: 'Seleccione Cobrador' },
                  ...lCobradores.map(cobrador => ({
                    value: cobrador.usuario_id,
                    label: cobrador.nombre
                  }))
                ].find(option => option.value === field.value) || null}
                onChange={(selectedOption) => {
                  field.onChange(selectedOption ? selectedOption.value : null);
                  onchangeCobrador(selectedOption);
                }}
                placeholder="Seleccione Cobrador"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '38px',
                    minHeight: '38px',
                    width: '95%',
                    borderColor: state.isFocused ? 'black' : '#cfd5db',
                    boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                    '&:hover': {
                      borderColor: 'gray'
                    }
                  }),
                  valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    height: '38px',
                    minHeight: '38px',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '95%'
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    width: '88%'
                  })
                }}
              />
            )}
          />
        </div> :
        ''
      }

      <div className="ms-auto"> {/* Utilizamos la clase ms-auto para alinear a la derecha */}

        {
          props.setModulo !== 'cobradores' ? (
            <button
              onClick={() => {
                if (props.setModulo === 'grupo') {
                  openModalHandler();
                } else if (props.setModulo === 'tipocliente') {
                  openModalHandlerTC();
                } else {
                  navigate('/panel/' + props.setModulo + '/crear');
                }
              }}
              className={`${style.botonCreateUser} `}
              type='button'
            >
              <span>
                {' '}
                {props.setModulo === 'grupo' || props.setModulo === 'tipocliente' ? (
                  // Comprueba si el módulo es 'grupo'
                  <FaPlus className='' color={'white'} size={25} /> // Renderiza FaPlus si es 'grupo'
                ) : props.setModulo === 'cobranza' ? (
                  // Comprueba si el módulo es 'cobranza'
                  <FiFilePlus className='' color={'white'} size={30} /> // Renderiza FiFilePlus si es 'cobranza'
                ) : (
                  <BiUserPlus className='' color={'white'} size={35} /> // Renderiza BiUserPlus por defecto
                )}
              </span>
            </button>
          ) : (
            ''
          )
        }


        <ModalGrupo
          className='modal'
          show={modalShow}
          setModalShow={setModalShow}
          close={closeModalHandler}
          setDataReferencias={setDataReferencias}
          data={dataReferencia}
        ></ModalGrupo>

        <ModalTipoClientes
          className='modal'
          show={modalShowTC}
          setModalShow={setModalShowTC}
          close={closeModalHandlerTC}
          setDataReferencias={setDataReferenciasTC}
          data={dataReferenciaTC}
        ></ModalTipoClientes>
      </div>
    </Box>
  );
};

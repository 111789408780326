import React, { useState } from 'react'
import style from './PasswordChangePage.module.sass'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { MdArrowBackIos } from 'react-icons/md'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import axiosConfig from '../../../utils/axiosConfig';

import { NotificationContainer, NotificationManager } from 'react-notifications';
const initialValues = {
  code: '',
  newPassword: '',
  confirmPassword: '',
};

const initailValidValues = {
  code: true,
  newPassword: true,
  confirmPassword: true,
};

const PasswordChangePage = () => {
  const [values, setValues] = useState(initialValues);
  const { code, newPassword, confirmPassword } = values;
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [validValues, setValidValues] = useState(initailValidValues);

  const [errorNew, setErrorNew] = useState('');
  const [errorConfirm, setErrorConfirm] = useState('');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  const toggle2 = () => {
    setOpen2(!open2);
  };

  const navigate = useNavigate();

  const handleChange = ({ target }) => {
    setValues((s) => ({ ...s, [target.name]: target.value }));
  };

  const handleBlur = ({ target }) => {
    setValidValues((s) => ({ ...s, [target.name]: values[target.name] !== '' }));
    if (target.name === 'newPassword') {
      const newPassword = target.value;
      if (newPassword.length < 6) {
        setErrorNew('The length of the password must be at least 6 characters.');
        setValidValues((s) => ({ ...s, [target.name]: false }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (code === '' || newPassword === '' || newPassword === '') {
      for (const item in values) {
        setValidValues((s) => ({ ...s, [item]: values[item] !== '' }));
        newPassword === '' && setErrorNew('Password is necessary.');
        confirmPassword === '' && setErrorConfirm('Confirm password is necessary.');
      }
      return;
    }

    if (newPassword !== confirmPassword) {
      setValidValues((s) => ({ ...s, confirmPassword: false }));
      setErrorConfirm("Passwords doesn't match.");
      setErrorNew("Passwords doesn't match.");
      return;
    } else {
      setValidValues((s) => ({ ...s, confirmPassword: true }));
      setValidValues((s) => ({ ...s, newPassword: true }));
    }

    axiosConfig({
      method: 'POST',
      url: '/auth/changePassword',
      data: {
        email,
        code,
        password: newPassword,
      },
    })
      .then((resp) => {
        console.log(resp);
        navigate('/passwordChangeSuccess');
      })
      .catch(async (error) => {
        NotificationManager.error(error, 'Error!', 2500);
        console.log('msj err: ', error);
      });
  };

  return (
    <div className={`${style.LoginPage}`}>
      <div className={style.back} />
      <form onSubmit={handleSubmit} className={style.form}>
        <div className='border-bottom mx-0 px-0 pt-0 w-10'>
          <Link className='fw-bolder text-dark ' to='/login'>
            <MdArrowBackIos size={25} />
          </Link>
        </div>

        <span className='flex justify-center mt-3 fw-bolder text-xl text-rose-900 font-sans'>
          Password recovery
        </span>

        <div className='col-12 mx-auto mt-4 mb-3'>
          <label className='text-center'>
            To change your password, please enter the 6 digit verification code sent to your E-mail
            and enter your new password.
          </label>
        </div>

        <div className='mb-2 mt-4'>
          <label
            htmlFor='codeInput'
            className={`fw-bolder text-gray-600 form-label ${validValues.code ? '' : 'is-invalid'}`}
          >
            Verification code
          </label>
          <input
            name='code'
            onChange={handleChange}
            onBlur={handleBlur}
            value={code}
            type='password'
            className='form-control'
            id='codeInput'
            aria-describedby='codeInput'
          />
          <div id='passwordInput' className={'text-red-800 mt-2 mb-8 text-sm'}>
            Verification code is necessary.
          </div>
        </div>

        <div className='mb-2 '>
          <label
            htmlFor='emailInput'
            className={`fw-bolder text-gray-600 form-label ${
              validValues.newPassword ? '' : 'is-invalid'
            }`}
          >
            New password
          </label>
          <input
            name='newPassword'
            onChange={handleChange}
            onBlur={handleBlur}
            value={newPassword}
            type={open === false ? 'password' : 'text'}
            className='form-control'
            id='passwordInput'
            aria-describedby='passwordInput'
          />
          <div className='text-2xl relative -top-[38px] left-[250px]'>
            {open === false ? (
              <AiFillEyeInvisible color='#555555' onClick={toggle} />
            ) : (
              <AiFillEye color='#555555' onClick={toggle} />
            )}
          </div>
          <div id='newPassword' className={'text-red-800 -mt-5 mb-8 text-sm'}>
            {errorNew}
          </div>
        </div>

        <div className='mb-2 '>
          <label
            htmlFor='emailInput'
            className={`fw-bolder text-gray-600 form-label ${
              validValues.confirmPassword ? '' : 'is-invalid'
            }`}
          >
            Confirm new password
          </label>
          <input
            name='confirmPassword'
            onChange={handleChange}
            onBlur={handleBlur}
            value={confirmPassword}
            type={open2 === false ? 'password' : 'text'}
            className='form-control'
            id='passwordConfirmInput'
            aria-describedby='passwordConfirmInput'
          />
          <div className='text-2xl relative -top-[38px] left-[250px]'>
            {open2 === false ? (
              <AiFillEyeInvisible color='#555555' onClick={toggle2} />
            ) : (
              <AiFillEye color='#555555' onClick={toggle2} />
            )}
          </div>
          <div id='passwordConfirmInput' className={'text-red-800 -mt-5 mb-8 text-sm'}>
            {errorConfirm}
          </div>
        </div>

        <div className='d-grid gap-2 mt-5 col-12 mx-auto'>
          <button
            type='submit'
            className='px-4 py-2 border-none rounded bg-red-500 hover:bg-red-400 text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-rose-500 focus:ring-opacity-80 cursor-pointer'
          >
            Change password
          </button>
        </div>
      </form>

      <NotificationContainer />
    </div>
  );
};

export default PasswordChangePage
import React from 'react';
import { useTable } from 'react-table';

function DataTable({ columns, data }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data });

    return (
        <table {...getTableProps()} style={{ width: '100%', textAlign: 'center' }} >
            <thead style={{ borderRadius: '40px' }}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} style={{ backgroundColor: '#EEEEEE', color: '#000' }}>{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                //console.log("cell: ", cell)
                                if (cell.column.id == 'aval' && cell.value == true) {
                                    return <td {...cell.getCellProps()}>Si</td>;
                                } else if (cell.column.id == 'aval' && cell.value == false) {
                                    return <td {...cell.getCellProps()}>No</td>;
                                } else {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                }
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default DataTable;

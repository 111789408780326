import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import style from './forgottenPasswordPage.module.sass';
import axiosConfig from '../../../utils/axiosConfig';

import { MdArrowBackIos } from 'react-icons/md';

import { validateEmail } from '../../../utils/validateEmail';

import 'animate.css';

const ForgottenPasswordPage = () => {
  const emailRef = useRef(null);
  const [emailValid, setEmailValid] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { value: email } = emailRef.current;

    if (email === '' || !validateEmail(email)) {
      setEmailValid(true);
      setErrorMsg('Please enter a valid E-mail address.');
      return;
    } else {
      setEmailValid(false);
    }

    axiosConfig({
      method: 'POST',
      url: '/auth/code/email',
      data: {
        email,
      },
    }).then((resp) => {
      if (!resp.success) {
        setEmailValid(true);
        setErrorMsg(resp.message);
        return;
      } else {
        navigate(`/PasswordChange?email=${email}`);
      }
    });
  };

  return (
    <div>
      <div className={style.back} />
      <div className='flex items-center justify-center h-screen animate__animated animate__slideInLeft animate__faster'>
        <form onSubmit={handleSubmit} className={style.form}>
          <div className='border-bottom mx-0 px-0 pb-4 pt-0 w-10'>
            <Link className='fw-bolder text-dark ' to='/login'>
              <MdArrowBackIos size={25} />
            </Link>
          </div>

          <span className='flex justify-center fw-bolder text-xl text-rose-900 font-sans'>
            Password recovery
          </span>

          <div className='col-9 mx-auto mt-4 mb-4'>
            <label className='text-center'>
              A 6 digit verification code will be sent to your E-mail for you to be able to change
              your password.
            </label>
          </div>

          <div className='relative'>
            <div className='mb-5 pb-3 mx-4 mt-12'>
              <label
                htmlFor='emailInput'
                className={`fw-bolder text-gray-800 form-label ${emailValid ? 'is-invalid' : ''}`}
              ></label>
              <input
                ref={emailRef}
                type='text'
                id='emailInput'
                aria-describedby='validationEmail'
                placeholder='Username'
                className={`${
                  emailValid
                    ? 'peer h-10 w-full border-b-rose-600 border-t-0 border-x-0 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600 animate__animated animate__shakeX'
                    : 'peer h-10 w-full border-y-0 border-x-0 text-gray-900 placeholder-transparent focus:outline-none focus:border-gray-300'
                }`}
              />
              <p id='validationEmail' className={`${emailValid ? 'text-red-800' : 'hidden'}`}>
                {errorMsg}
              </p>
              <label
                for='emailInput'
                className='absolute left-8 -top-5 text-rose-900 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-5 peer-focus:text-rose-900 peer-focus:text-sm'
              >
                E-mail
              </label>
            </div>

            <div className='d-grid gap-2 mt-2 col-12 mx-auto'>
              <button
                type='submit'
                className='px-4 py-2 -mt-3 rounded border-none bg-red-500 hover:bg-red-400  text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-rose-500 focus:ring-opacity-80 cursor-pointer'
              >
                Send email
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgottenPasswordPage;

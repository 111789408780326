import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import '../cerrar_caja_chica/modalCerrarCajaChica.css';
import axios from "axios"; // Importa axios
import { NotificationManager } from "react-notifications";

const ModalCerrarCajaChica = (props) => {
    const { show, cajachica_id } = props;
    console.log('cajachica_id recibido como prop:', cajachica_id);
    const close = props.close;

    const {
        formState: { errors },
        register,
    } = useForm();

    useEffect(() => {
        // Define las reglas de validación utilizando la librería react-hook-form
        register('monto_final', { required: true });
    }, [register]);

    const [inputValues, setInputValues] = useState({
        monto_final: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
    }, []);

 
    const [montoAbonos, setMontoAbonos] = useState([]);
    const [totalAbonos, setTotalAbonos] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            if (show) {
                const headers = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                };
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}cobrador/cajasChica/estimado/${cajachica_id}`, headers);
                    console.log("monto calculado: ", response);
                    setMontoAbonos(response.data.montoFinal);
                    setTotalAbonos(response.data.totalAbonos);
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            }
        };
    
        fetchData();
    }, [show]);

    const handleDelete = async () => {
        try {
            const headers = {
                headers: {
                    //"Content-Type": "application/x-www-form-urlencoded",
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            };
            // Realiza la solicitud POST a la API
            console.log("data", inputValues);
            console.log("header", headers);
            await axios.put(`${process.env.REACT_APP_API_URL}cobrador/cajaChica/${cajachica_id}`, inputValues, headers);
            NotificationManager.success('Caja chica cerrada exitosamente.');

            setInputValues({
                monto_final: ''
            });

            close();
            // Después de agregar el grupo exitosamente, dispara el evento personalizado
            const event = new Event('grupoAgregado');
            window.dispatchEvent(event);

        } catch (error) {
            console.error('Error al cerrar la caja chica:', error);
            NotificationManager.error('Error al cerrar la caja chica.');
        }
    };

    const handleCancel = () => {
        // Vaciar los campos de entrada al cancelar
        setInputValues({
            monto_final: ''
        });

        close();
    };

    return (
        <div className="modal-containerCCH" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-background" onClick={close}></div>
            <div className="modal-grupo">
                <div className="headerFormulario">
                    <h3>Cerrar caja chica</h3>
                </div>
                <div className="formularioCerrarCCH">
                    <div className="form-group">
                        <label htmlFor="monto_calculado">Monto calculado:</label><br />
                        <input
                            type="text"
                            name="monto_calculado"
                            id="monto_calculado"
                            value={montoAbonos}
                            onChange={handleChange}
                            placeholder="Monto calculado"
                            disabled
                        />
                        {errors.monto_calculado && <p style={{ color: 'red' }}>El monto_calculado es requerido</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="monto_final">Monto entregado:</label><br />
                        <input
                            type="text"
                            name="monto_final"
                            id="monto_final"
                            value={inputValues.monto_final}
                            onChange={handleChange}
                            placeholder="Ingrese el monto final"
                        />
                        {errors.monto_final && <p style={{ color: 'red' }}>El monto_final es requerido</p>}
                    </div>
                </div>
                <div className="grupo-botones">
                    <div className="divbtn">
                        <button className="btn btn-success mx-1 boton-modal" type='button' onClick={handleDelete}>Agregar</button>
                        <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}> Cancel </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCerrarCajaChica;

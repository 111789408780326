import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"; // Importa axios
import style from "./UpdateConveniosPage.module.sass";
import { MdArrowBackIosNew } from 'react-icons/md';
import { MdOutlineContactPage, } from 'react-icons/md';
import { FiFilePlus } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { FaTrash, FaPencilAlt } from 'react-icons/fa';
import moment from 'moment';
import { useForm, Controller } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import DataTable from '../../../components/modal_convenios/DataTable';
import Modal from '../../../components/modal_convenios/modal_agregar/Modal_Convenios';
import Select from 'react-select';
import { Stack } from '@mui/material'; // Si estás usando Material-UI
import { Switch as AntSwitch } from 'antd'; // Si estás usando Ant Design
import { TRUE } from "sass";

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const UpdateConveniosPage = () => {
  const { id } = useParams(); // Obtiene el usuario_id de la URL
  const [cobranzaData, setCobranzaData] = useState(null);
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const backlUser = () => {
    navigate("/panel/cobranza");
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm();

  //DataTable
  const mensajeVacio = '';

  const [dataReferencia, setDataReferencias] = useState([]);

  useEffect(() => {
    console.log(dataReferencia);
  }, [dataReferencia]);

  const [modalShow, setModalShow] = useState(false);
  const closeModalHandler = () => {
    setModalShow(false);
  };

  const openModalHandler = (e) => {
    setModalShow(true);
  };

  const columns = React.useMemo(
    () => [
      { Header: 'Codigo', accessor: 'codigo' },
      { Header: 'Modelo', accessor: 'modelo' },
      { Header: 'Color', accessor: 'color' },
      { Header: 'Precio', accessor: 'precio' },
      //{ Header: 'Accion', accessor: 'accion' },
    ],
    []
  );

  //Obtener Clientes
  const [clientesLista, setClientes] = useState([]);
  const [filterTC, setFilterTC] = useState(initialFilter);

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterTC }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}clientes`, headers);
        console.log("Respuesta completa de la API clientes:", response.data);
        setClientes(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los clientesLista:", error);
      }
    };

    if (token) {
      fetchClientes();
    }
  }, [token]);

  //Obtener Clientes
  const [cobradoresLista, setCobradores] = useState([]);
  const [filterCobradores, setFilterCobradores] = useState(initialFilter);

  useEffect(() => {
    const fetchCobradores = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterCobradores }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}cobrador`, headers);
        console.log("Respuesta completa de la API Cobradores:", response.data);
        setCobradores(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los cobradores:", error);
      }
    };

    if (token) {
      fetchCobradores();
    }
  }, [token]);

  // Obtener Tipo Clientes
  const [tipoClientes, setTipoClientes] = useState([]);

  useEffect(() => {
    const fetchTipoClientes = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterTC }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}tipocliente`, headers);
        console.log("Respuesta completa de la API:", response.data);

        // Verifica si response.data.rows es un array
        if (Array.isArray(response.data.rows)) {
          setTipoClientes(response.data.rows);
        } else {
          console.error("La respuesta no contiene un array en 'rows'.");
          setTipoClientes([]);
        }
      } catch (error) {
        console.error("Error al obtener los tipoClientes:", error);
        setTipoClientes([]); // Maneja el error asignando un array vacío
      }
    };

    if (token) {
      fetchTipoClientes();
    }
  }, [token]);

  const [loadingTipoClientes, setLoadingTipoClientes] = useState(true);

  // Obtener Tipo Clientes
  useEffect(() => {
    const fetchTipoClientes = async () => {
      setLoadingTipoClientes(true);
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterTC }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}tipocliente`, headers);
        console.log("Respuesta completa de la API:", response.data);

        if (Array.isArray(response.data.rows)) {
          setTipoClientes(response.data.rows);
        } else {
          console.error("La respuesta no contiene un array en 'rows'.");
          setTipoClientes([]);
        }
      } catch (error) {
        console.error("Error al obtener los tipoClientes:", error);
        setTipoClientes([]);
      } finally {
        setLoadingTipoClientes(false);
      }
    };

    if (token) {
      fetchTipoClientes();
    }
  }, [token]);



  // Función para calcular la suma total de los precios de los convenios
  const [subtotal, setSubtotal] = useState(0); // Estado para el subtotal
  const [iva, setIVA] = useState(0); // Estado para el IVA
  const [total, setTotal] = useState(0); // Estado para el total
  const [enganche, setEnganche] = useState(0); // Estado para el enganche
  const [saldoRestante, setSaldoRestante] = useState(0); // Estado para el saldo restante
  const [tipoAbono, setTipoAbono] = useState(''); // Estado para el tipo de abono
  const [fechaInicial, setFechaInicial] = useState(''); // Estado para la fecha inicial
  const [fechaSiguiente, setFechaSiguiente] = useState(''); // Estado para la fecha del siguiente abono

  const [recalcular, setRecalcular] = useState(false);

  // Función para manejar el cambio en el campo de enganche
  const handleEngancheChange = (e) => {
    const { value } = e.target;
    setEnganche(Number(value)); // Convierte el valor a número y actualiza el estado del enganche

    setRecalcular(true)
  };

  // Función para manejar el cambio en el campo tipo_abono
  const handleTipoAbonoChange = (e) => {
    const { value } = e.target;
    setTipoAbono(value); // Actualiza el estado del tipo de abono

    // Calcula la fecha del siguiente abono dependiendo del tipo de abono y la fecha inicial
    calculateFechaSiguiente(value, fechaInicial);
  };

  // Función para calcular la fecha del siguiente abono
  const calculateFechaSiguiente = (tipoAbono, fechaInicial) => {
    let dias = tipoAbono === 'semanal' ? 7 : 15; // Si es semanal, se suman 7 días, si es quincenal, se suman 15 días

    if (fechaInicial) {
      const fechaInicialObj = new Date(fechaInicial);

      // Verifica si la fecha inicial es válida
      if (!isNaN(fechaInicialObj.getTime())) {
        const fechaSiguienteObj = new Date(fechaInicialObj.getTime() + (dias * 24 * 60 * 60 * 1000));
        setFechaSiguiente(fechaSiguienteObj.toISOString().split('T')[0]);
      } else {
        // La fecha inicial no es válida, se establece la fecha del siguiente abono como vacía
        setFechaSiguiente('');
      }
    } else {
      // La fecha inicial no está establecida, se establece la fecha del siguiente abono como vacía
      setFechaSiguiente('');
    }
  };

  const [switchValue, setSwitchValue] = useState(true);
  const [switchValueText, setSwitchValueText] = useState('Casa');
  const handleSwitchChange = (checked) => {

    if (checked) {
      setSwitchValueText('Casa');
    }
    else {
      setSwitchValueText('Trabajo');
    }
    setSwitchValue(checked);
    console.log('Switch value:', checked); // Puedes usar el valor como necesites
  };

  useEffect(() => {
    // Verifica que token tenga un valor antes de hacer la solicitud
    if (token) {
      const fetchUserData = async () => {
        try {
          const headers = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
          };

          const response = await axios.get(`${process.env.REACT_APP_API_URL}convenios/${id}`, headers);
          console.log('response CRIMINAL: ', response.data);
          console.log('ID: ', id);

          const fechaPrimerAbonoUTC = moment.utc(response.data.fecha_primer_abono);
          const fechaPrimerAbonoFormatted = fechaPrimerAbonoUTC.format("YYYY-MM-DD");

          const fechaSiguienteAbonoUTC = moment.utc(response.data.fecha_siguiente_abono);
          const fechaSiguienteAbonoFormatted = fechaSiguienteAbonoUTC.format("YYYY-MM-DD");

          setCobranzaData(response.data);
          setValue("subtotal", response.data.subtotal);
          setSubtotal(response.data.subtotal);
          setValue("iva", response.data.iva);
          setIVA(response.data.iva);
          setValue("total", response.data.total);
          setTotal(response.data.total);

          setSwitchValue(response.data.direccionCobro);
          if (response.data.direccionCobro) {
            setSwitchValueText('Casa');
          }
          else {
            setSwitchValueText('Trabajo');
          }

          if (response.data.tipocliente_id != null) {
            setTipoClientes(response.data.tipocliente_id);
            setValue("tipocliente_id", response.data.tipocliente_id);
          }

          if (response.data.enganche) {
            setValue("enganche", response.data.enganche); // Establece el valor del campo "enganche" utilizando setValue
            setEnganche(response.data.enganche);
          }

          if (response.data.saldo_restante) {
            setValue("saldo_restante", response.data.saldo_restante); // Establece el valor del campo "saldo_restante" utilizando setValue
          }

          setValue("fecha_primer_abono", fechaPrimerAbonoFormatted);
          setValue("fecha_siguiente_abono", fechaSiguienteAbonoFormatted);

          if (response.data.tipo_abono) {
            setValue("tipo_abono", response.data.tipo_abono);
          }

          setValue("cantidad_abono", response.data.cantidad_abono);
          setValue("nota", response.data.nota);

          var nArray = [];
          let newidCounter = 1;

          response.data.movimientos.forEach(element => {
            const newid = newidCounter;

            const buttonAccion = React.createElement('button', {
              id: `btnExc_${newid}`, // Utiliza un id único para cada botón de eliminación
              className: 'btn btn-sm btn-danger mx-1',
              type: 'button',
              onClick: () => {
                // Filtra los datos para excluir el elemento que se está eliminando
                setDataReferencias((prevData) => {
                  // Filtra los datos para excluir el elemento que se va a eliminar
                  const newData = prevData.filter(item => item.id !== newid);

                  return newData;
                });
                setRecalcular(true);
              }
            }, React.createElement(FaTrash, { size: 25, color: 'white' }));

            nArray.push({
              codigo: element.codigo,
              modelo: element.modelo,
              color: element.color,
              precio: element.precio,
              //accion: buttonAccion,
              id: newid
            });
            newidCounter++;
          });

          setDataReferencias(nArray);

        } catch (error) {
          console.error('Error al obtener los datos del convenio:', error);
        }
      };
      fetchUserData();
    }
  }, [id, token]);


  useEffect(() => {
    // Actualiza los estados de subtotal, IVA y total
    if (recalcular) {
      /*  const sub = calcularSubtotal();
       const imp = calcularIVA(sub);
       const tot = calcularTotal(sub, imp);
       const saldo = calcularSaldoRestante(tot, enganche); */
      calcular();
    }
    setRecalcular(false);

    /*setSubtotal(sub);
    setIVA(imp);
    setTotal(tot);
    setSaldoRestante(saldo);*/
  }, [recalcular]); // Ejecuta el efecto cuando cambia el array de convenios

  const calcular = () => {
    console.log('Ya entro 🥵🥵🥵🥵')
    var nsubt = 0, niva = 0, ntot = 0, saldo_rest = 0;
    const ivaPorcentaje = 0.16;
    dataReferencia.forEach(element => {
      // Calcula el subtotal por elemento (precio * cantidad)
      const subtotalElemento = Number(element.precio);

      // Agrega el subtotal al subtotal total
      nsubt += Number(subtotalElemento);

      // Calcula el IVA por elemento (subtotal * ivaPorcentaje)
      const ivaElemento = Number(subtotalElemento) * Number(ivaPorcentaje);

      // Agrega el IVA al IVA total
      niva += Number(ivaElemento);

      // Calcula el total por elemento (subtotal + iva)
      const totalElemento = Number(subtotalElemento) + Number(ivaElemento);

      // Agrega el total al total general
      ntot += Number(totalElemento);
    });


    console.log("engancheee: ", enganche);
    if (enganche != 0) {
      saldo_rest = Number(ntot) - Number(enganche);
    }

    console.log("subt ", nsubt);
    console.log("iva ", niva);
    console.log("tot ", ntot);

    setValue("subtotal", nsubt);
    setSubtotal(nsubt);
    setValue("iva", niva);
    setIVA(niva);
    setValue("total", ntot);
    setTotal(ntot);

    console.log("saldo restanta: ", saldo_rest);
    setSaldoRestante(saldo_rest);
    setValue("saldo_restante", saldo_rest);
  };

  const onEdit = async (data) => {
    try {
      console.log("data antes de todo: ", data);
      // Convertir el valor de "cliente_id" a un número si es un string
      const clienteId = watch("cliente_id");
      if (typeof clienteId === 'string') {
        data.cliente_id = parseInt(clienteId);
      }

      // Convertir el valor de "usuario_cobro" a un número si es un string
      const usuarioCobroId = watch("usuario_cobro");
      if (typeof usuarioCobroId === 'string') {
        data.usuario_cobro = parseInt(usuarioCobroId);
      }

      const tipoCliente = watch("tipocliente_id");
      data.tipocliente_id = parseInt(tipoCliente);


      // Añade los datos adicionales necesarios al objeto data antes de enviar la solicitud
      data.subtotal = subtotal;
      data.iva = iva;
      data.total = total;

      data.direccionCobro = switchValue;


      const headers = {
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded",
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };

      let requestData = {
        ...data,
        subtotal: subtotal,
        iva: iva,
        total: total,
        convenio_movimientos: dataReferencia
      };

      // Realiza la solicitud POST a la API
      console.log("data", data);
      console.log("header", headers);
      await axios.put(`${process.env.REACT_APP_API_URL}convenios/${id}`, requestData, headers);
      NotificationManager.success('Cobranza modificada exitosamente');
      // Redirige a la página deseada
      navigate('/panel/cobranza');
    } catch (error) {
      console.error('Error al modificar la cobranza:', error);
      NotificationManager.error('Error al modificar la cobranza');
    }
  };

  return (
    <div className={`${style.UpdateConveniosPage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <MdOutlineContactPage
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ mixBlendMode: 'difference' }}>Editar una cobranza</span>
        </h3>
      </div>
      {cobranzaData && (
        <form form onSubmit={handleSubmit(onEdit)}>
          <div className={`${style.box} bg-white`}>
            <div className={`${style.boxBarra} fw-normal p-2 border-secondary`}>
              <h3 className='h3 fw-bold' style={{ fontSize: 20 + 'px' }}>
                <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer', marginRight: '10px' }} />
              </h3>
              <button type="submit">
                <FaPencilAlt
                  color={'white'}
                  size={'30'}
                />
              </button>
            </div>
          </div>

          <div className={`${style.formularioCliente} table-responsive bg-white`} style={{ marginTop: '40px', marginBottom: '50px' }}>
            <div className={`${style.referencias}`}>
              <div className={`${style.headerProducto} `}>
                <h3>Compra</h3>
              </div>
              {/*<div className={`${style.referenciasBoton}`}>
                <button className="botonReferencias" type='button' onClick={openModalHandler} disabled>
                  <FaPlus
                    color={'white'}
                    size={'30'}
                  /></button>
              </div>*/}
              <div className={`${style.dataTable} `}>
                <DataTable columns={columns} data={dataReferencia} />
                {dataReferencia.length === 0 && (
                  <tr>
                    <td colSpan={6} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                      <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                    </td>
                  </tr>
                )}
              </div>
              <div className={`${style.compraTotal}`}>
                {/*<div className={`${style.divPrecios}`}>
                  <label htmlFor="subtotal">Subtotal:</label>
                  <input id="subtotal" {...register("subtotal")} placeholder="Subtotal de la compra" disabled />
                </div>
                <div className={`${style.divPrecios}`}>
                  <label htmlFor="iva">IVA:</label>
                  <input id="iva" {...register("iva")} placeholder="IVA de la compra" disabled />
                </div>*/}
                <div className={`${style.divPrecios}`}>
                  <label htmlFor="total">Total:</label>
                  <input id="total" {...register("total")} placeholder="Total de la compra" disabled />
                </div>
              </div>
            </div>
          </div>

          <div className={`${style.formularioUsuarios} `}>
            <div className={`${style.headerFormulario} `}>
              <div className={`${style.headerProducto} `}>
                <h3>Datos de cobranza</h3>
              </div>
            </div>
            <div className={`${style.formulario} `}>
              <div className="form-group">
                <label htmlFor="cliente_id">Cliente:</label><br />
                <select disabled id="cliente_id" {...register("cliente_id", { required: false })} style={{ backgroundColor: '#FAFBFB' }}>
                  {/* <option value="0">Selecciona al clientes (opcional)</option> */}
                  {clientesLista.length > 0 ? (
                    clientesLista.map((clientes) => (
                      (clientes.cliente_id == cobranzaData.cliente_id) ?
                        <option key={clientes.cliente_id} value={clientes.cliente_id} selected>
                          {clientes.nombre}
                        </option> :
                        <option key={clientes.cliente_id} value={clientes.cliente_id}>
                          {clientes.nombre}
                        </option>
                    ))
                  ) : (
                    <option disabled>No hay grupos disponibles</option>
                  )}
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="enganche">Enganche:</label><br />
                <input disabled id="enganche" {...register("enganche")}
                  onChange={(e) => {
                    //setValue("enganche", e.target.value); // Establece el valor del campo "enganche" utilizando setValue
                    //setEnganche(e.target.value);
                    handleEngancheChange(e); // Llama a la función handleEngancheChange para realizar otras operaciones si es necesario
                  }}
                  placeholder=""
                  type="number"
                />
                {errors.enganche && <p style={{ color: 'red' }}>El enganche es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="saldo_restante">Saldo restante:</label><br />
                <input disabled id="saldo_restante" {...register("saldo_restante")} placeholder="" />
                {errors.saldo_restante && <p style={{ color: 'red' }}>El saldo restante es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="tipo_abono">Tipo de abono:</label><br />
                <select
                  id="tipo_abono"
                  {...register("tipo_abono", { required: false })}
                  onChange={(e) => {
                    setValue("tipo_abono", e.target.value); // Establece el valor del campo "tipo_abono" utilizando setValue
                    handleTipoAbonoChange(e); // Llama a la función handleTipoAbonoChange para realizar otras operaciones si es necesario
                  }}
                >
                  <option value="" disabled>Selecciona un tipo de abono.</option>
                  <option value="semanal">Semanal</option>
                  <option value="quincenal">Quincenal</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="fecha_primer_abono">Fecha Inicial:</label><br />
                <input id="fecha_primer_abono" type="date" {...register("fecha_primer_abono")} disabled placeholder="Fecha de nacimiento del clientes" />
                {errors.fecha_primer_abono && <p style={{ color: 'red' }}>La fecha de nacimiento es requerida</p>}
              </div>

              <div className="form-group">
                <label htmlFor="fecha_siguiente_abono">Fecha del siguiente abono:</label><br />
                <input id="fecha_siguiente_abono" type="date" {...register("fecha_siguiente_abono")} /*value={fechaSiguiente}*/ placeholder="Fecha del siguiente abono" />
                {errors.fecha_siguiente_abono && <p style={{ color: 'red' }}>La fecha del siguiente abono es requerida</p>}
              </div>

              <div className="form-group">
                <label htmlFor="cantidad_abono">Cantidad de abono:</label><br />
                <input id="cantidad_abono" {...register("cantidad_abono")} placeholder="Cantidad a abonar (Pagos)." />
                {errors.cantidad_abono && <p style={{ color: 'red' }}>La cantidad del abono es requerida</p>}
              </div>

              <div className={style.form_group}>
                <label htmlFor="tipocliente">Tipo de cliente:</label>
                {loadingTipoClientes ? (
                  <p>Cargando tipos de cliente...</p>
                ) : (
                  <Controller
                    name="tipocliente_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={Array.isArray(tipoClientes) ? tipoClientes.map(tipocliente => ({
                          value: tipocliente.tipocliente_id,
                          label: tipocliente.nombre
                        })) : []}
                        value={Array.isArray(tipoClientes) ? tipoClientes
                          .map(tipocliente => ({
                            value: tipocliente.tipocliente_id,
                            label: tipocliente.nombre
                          }))
                          .find(option => option.value === field.value) : null}
                        onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                        placeholder="Seleccione un tipo de cliente"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: '50px',
                            minHeight: '50px',
                            width: '95%',
                            borderColor: state.isFocused ? 'black' : 'gray',
                            boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                            '&:hover': {
                              borderColor: 'gray'
                            }
                          }),
                          valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            height: '50px',
                            minHeight: '50px',
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            width: '95%'
                          }),
                          singleValue: (baseStyles) => ({
                            ...baseStyles,
                            display: 'flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            width: '88%'
                          })
                        }}
                      />
                    )}
                  />
                )}
              </div>

              <div className={style.form_group}>
                <label htmlFor="cobrador">Cobrador:</label>
                <Controller
                  name="usuario_cobro"
                  control={control}
                  defaultValue={cobranzaData.usuario_cobro}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={cobradoresLista.map(cobrador => ({
                        value: cobrador.usuario_id,
                        label: cobrador.nombre
                      }))}
                      value={cobradoresLista
                        .map(cobrador => ({
                          value: cobrador.usuario_id,
                          label: cobrador.nombre
                        }))
                        .find(option => option.value === field.value) || null}
                      onChange={(selectedOption) => field.onChange(selectedOption ? selectedOption.value : null)}
                      placeholder="Seleccione un cobrador"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: '50px',
                          minHeight: '50px',
                          width: '95%',
                          borderColor: state.isFocused ? 'black' : 'gray', // Cambia los colores según tus preferencias
                          boxShadow: state.isFocused ? '0 0 0 1px black' : 'none',
                          '&:hover': {
                            borderColor: 'gray' // Color del borde al pasar el ratón
                          }
                        }),
                        valueContainer: (baseStyles) => ({
                          ...baseStyles,
                          height: '50px',
                          minHeight: '50px',
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          width: '95%'
                        }),
                        singleValue: (baseStyles) => ({
                          ...baseStyles,
                          display: 'flex',
                          alignItems: 'center',
                          alignContent: 'center',
                          width: '88%'
                        })
                      }}
                    />
                  )}
                />
              </div>

              <div className="form-group">
                <label htmlFor="domicilio_cobro">Domicilio de cobro: {switchValueText}</label><br />
                <Stack direction="row" spacing={2} style={{ paddingTop: '15px' }}>
                  <div>Trabajo</div>
                  <AntSwitch checked={switchValue} onChange={handleSwitchChange} />
                  <div>Casa</div>
                </Stack>

              </div>



            </div>
            <div className={`${style.formularioNota} `}>
              <div className="form-group">
                <label htmlFor="nota">Notas:</label><br />
                <textarea id="nota" type="text" {...register("nota")} placeholder="Agrega una nota para dar mas detalles" />
                {errors.nota && <p style={{ color: 'red' }}>La fecha de nacimiento es requerida</p>}
              </div>
            </div>
          </div>
        </form>
      )}
      {/* Agrega el formulario para ingresar los datos del usuario */}

      <Modal
        className='modal'
        show={modalShow}
        setModalShow={setModalShow}
        close={closeModalHandler}
        setDataReferencias={setDataReferencias}
        data={dataReferencia}
        recalcular={setRecalcular}
      ></Modal>
    </div >
  );
};

export default UpdateConveniosPage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./CobradoresPage.module.sass";
import axiosConfig from '../../../utils/axiosConfig';
import axios from "axios"; // Importa axios
import { FaTrash, FaPencilAlt, FaCheck } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { Pagination } from '../../../components/pagination/Pagination';
import { Filter } from '../../../components/filter/Filter';
import { NotificationManager } from "react-notifications";
import Swal from 'sweetalert2'; // Importa SweetAlert
import { HiUserGroup } from "react-icons/hi";
import ModalCajaChica from '../../../components/cobradores/caja_chica/modalCajaChica';
import ModalCerrarCajaChica from "../../../components/cobradores/cerrar_caja_chica/modalCerrarCajaChica";
import { LiaMotorcycleSolid } from "react-icons/lia";
import { HiOutlineCash } from "react-icons/hi";
import { TbCashBanknoteOff } from "react-icons/tb";
import { FaRegListAlt } from "react-icons/fa";

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const CobradoresPage = () => {
  const navigate = useNavigate();

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const [lClientes, setLClientes] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  const [modalShowEditar, setModalShowEditar] = useState(false);

  const [searchBtn, setSearchBtn] = useState(false);
  const [activeFilter, setActiveFilter] = useState(true);

  const [filter, setFilter] = useState(initialFilter);
  const mensajeVacio = '';

  const [refreshClientes, setRefreshClientes] = useState(false);

  const [detailCliente, setDetailClientes] = useState({});

  // CODIGO PARA MODAL CERRAR CAJA CHICA
  const [dataReferenciaCCH, setDataReferenciasCCH] = useState([]);
  const [modalShowCCH, setModalShowCCH] = useState(false);
  const closeModalHandlerCCH = () => {
    setModalShowCCH(false);
  };

  const [cajaChicaId, setCajaChicaId] = useState(null);

  const openModalHandlerCCH = (cajachica_id) => {
    setCajaChicaId(cajachica_id);
    setModalShowCCH(true);
    console.log('cajachica_id:', cajachica_id);
  };
  //

  useEffect(() => {
    if (refreshClientes) {
      getCobradores();
    }
    setRefreshClientes(false);
  }, [refreshClientes]);

  useEffect(() => {
    setFilter(() => {
      return { ...filter, status: activeFilter };
    });
    setRefreshClientes(true);
  }, [activeFilter]);

  useEffect(() => {
    if (searchBtn && searchInput != null) {
      setFilter(() => {
        return { ...filter, search: searchInput };
      });
      setRefreshClientes(true);
      setSearchBtn(false);
    }
  }, [searchBtn]);

  useEffect(() => {
    const handleGrupoAgregado = () => {
      getCobradores(); // Llama a la función para actualizar la tabla
    };
    const handleGrupoEditado = () => {
      getCobradores(); // Llama a la función para actualizar la tabla
    };
    // Agrega el event listener para el evento personalizado
    window.addEventListener('grupoAgregado', handleGrupoAgregado);
    window.addEventListener('grupoEditado', handleGrupoEditado);
    // Limpia el event listener cuando el componente se desmonte para evitar fugas de memoria
    return () => {
      window.removeEventListener('grupoAgregado', handleGrupoAgregado);
      window.removeEventListener('grupoEditado', handleGrupoEditado);
    };
  }, []);


  const [pageCount, setPageCount] = useState(0);

  const getCobradores = () => {
    axiosConfig({
      method: 'GET',
      url: '/cobrador',
      params: { ...filter },
    })
      .then((result) => {
        console.log("Carga de datos: ", result);

        setLClientes(result.rows);
        setPageCount(Math.ceil(result.count / 10));
      })
      .catch((err) => console.log(err));
  };

  const handlePageClick = (event) => {
    setFilter(() => {
      return { ...filter, page: event.selected + 1 };
    });
    setRefreshClientes(true);
  };

  const closeModalHandler = () => {
    setModalShow(false);
  };

  const closeModalHandlerEditar = () => {
    setModalShowEditar(false);
  };

  //Modal 
  const [dataReferencia, setDataReferencias] = useState([]);
  const [dataReferenciaEditar, setDataReferenciasEditar] = useState([]);

  const [grupoId, setGrupoId] = useState(null);

  // Función para abrir el modal y establecer el ID del cobradores seleccionado
  const openModalHandler = (cobrador_id) => {
    setGrupoId(cobrador_id);
    setModalShowEditar(true);
    console.log('cobrador_id:', cobrador_id);
  };

  return (
    <div className={`${style.CobradoresPage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <LiaMotorcycleSolid
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ 'mix-blend-mode': 'difference' }}>Cobradores</span>
        </h3>
      </div>


      <Filter
        setActiveFilter={setActiveFilter}
        setSearchInput={setSearchInput}
        setSearchBtn={setSearchBtn}
        setModulo='cobradores'
        token={token}
      />

      <div className={`${style.table} table-responsive bg-white`}>
        <table className='table table-borderless border-bottom'>
          <thead>
            <tr className='text-start border-bottom'>
              <th className='text-center'>Nombre</th>
              <th className='text-center'>Telefono</th>
              <th className='text-center'>Direccion</th>
              <th className='text-center'>Activo</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lClientes.length > 0 &&
              lClientes.map((u) => (
                <tr className='text-start border-bottom align-middle' key={u.usuario_id}>
                  <td className='text-center'>{u.nombre + ' ' + u.apellido}</td>
                  <td className='text-center'>{u.telefono}</td>
                  <td className={`text-center ${style['direccion-width']}`}>{u.direccion}</td>
                  <td className='text-center'>
                    {u.activo ? (
                      <span className='badge bg-success rounded-pill'>Activo</span>
                    ) : (
                      <span className='badge bg-danger rounded-pill'>Inactivo</span>
                    )}
                  </td>
                  <td className={`text-center ${style['accionesWidth']}`}>
                    <button
                      id='btnAgregarCCH'
                      className='btn btn-sm btn-success mx-1'
                      onClick={() => {
                        openModalHandler(u.usuario_id)
                        console.log(u.usuario_id);
                      }}>
                      <HiOutlineCash size={25} color='white' />
                    </button>
                    {
                      (u.cajaChica[0]?.cajachica_id != null) ? <button
                        id='btnEliminarCCH'
                        className='btn btn-sm btn-danger mx-1'
                        onClick={() => {
                          openModalHandlerCCH(u.cajaChica[0]?.cajachica_id)
                          console.log(u.cajaChica[0]?.cajachica_id);
                        }}>
                        <TbCashBanknoteOff size={25} color='white' />
                      </button> : ''
                    }
                    <button
                      id='btnHistorialCCH'
                      className='btn btn-sm btn-secondary mx-1'
                      onClick={() => navigate(`/panel/cobradores/cajaChica/${u.usuario_id}`)}>
                      <FaRegListAlt size={25} color='white' />
                    </button>
                  </td>
                </tr>
              ))}
            {lClientes.length === 0 && (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                  <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>

      <ModalCajaChica
        className='modal'
        show={modalShowEditar}
        setModalShow={setModalShowEditar}
        close={closeModalHandlerEditar}
        setDataReferencias={setDataReferenciasEditar}
        data={dataReferenciaEditar}
        cobrador_id={grupoId}
      ></ModalCajaChica>

      <ModalCerrarCajaChica
        className='modal'
        show={modalShowCCH}
        setModalShow={setModalShowCCH}
        close={closeModalHandlerCCH}
        setDataReferencias={setDataReferenciasCCH}
        data={dataReferenciaCCH}
        cajachica_id={cajaChicaId}
      ></ModalCerrarCajaChica>
    </div>
  );
};

export default CobradoresPage;

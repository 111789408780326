import React, { useState, useEffect } from "react";
import style from '../../../pages/moduloCobradores/historialCobranza/HistorialCobranzaPage.module.sass';
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import '../historialCaja/historialCajaChica.css';
import axios from "axios";
import axiosConfig from '../../../utils/axiosConfig';
import { NotificationManager } from "react-notifications";
import { Pagination } from '../../pagination/Pagination';

const initialFilter = {
    search: null,
    status: true,
    order: 'desc',
    limit: 2,
    page: 1,
};

const HistorialCajaChica = (props) => {
    const { show, cajachica_id } = props;
    const { id } = useParams();

    const [totalAbono, setTotalAbono] = useState(0);

    const [lUsers, setLUsers] = useState([]);

    const [pageCount, setPageCount] = useState(0);
    console.log('cajachica_id recibido como prop:', cajachica_id);
    const mensajeVacio = '';
    const close = props.close;
    const [refreshUsers, setRefreshUsers] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const handlePageClick = (event) => {
        setFilter(() => {
            return { ...filter, page: event.selected + 1 };
        });
        setRefreshUsers(true);
    };

    const {
        formState: { errors },
        register,
    } = useForm();

    useEffect(() => {
        register('monto_final', { required: true });
    }, [register]);

    const [inputValues, setInputValues] = useState({
        monto_final: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
        getHistorial(cajachica_id);
    }, [cajachica_id]);

    useEffect(() => {
        if (refreshUsers) {
            getHistorial(cajachica_id);
        }
        setRefreshUsers(false);
    }, [refreshUsers, cajachica_id]);

    useEffect(() => {
        if (props.show) {
            getHistorial(cajachica_id);
        }
    }, [props.show, cajachica_id]);


    const getHistorial = (cajachica_id) => {
        axiosConfig({
            method: 'GET',
            url: `/cobrador/cajasChicas/${cajachica_id}`,
            params: { ...filter },
        })
            .then((result) => {
                console.log("Carga de datos: ", result);
                setLUsers(result.historial);
                setPageCount(Math.ceil(result.count / 10));
                const Abonos = result.historial.reduce((sum, u) => sum + u.convenioHistorial.abono, 0);
                setTotalAbono(Abonos);
            })
            .catch((err) => console.log(err));
    };

    const handleCancel = () => {
        setInputValues({
            monto_final: ''
        });
        close();
    };

    return (
        <div className="modal-containerHistorial" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-background" onClick={close}></div>
            <div className="modal-grupoHistorial">
                <div className="headerFormulario">
                    <h3>Historial</h3>
                </div>
                <div className={`${style.table} table-responsive bg-white`} style={{ margin: '0px 40px' }}>
                    <table className='table table-borderless border-bottom'>
                        <thead>
                            <tr className='text-start border-bottom'>
                                <th className='text-center'>Cliente</th>
                                <th className='text-center'>Abono</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lUsers && lUsers.length > 0 ? (
                                lUsers.map((u) => (
                                    <tr className='text-start border-bottom align-middle' key={u.cajachica_id}>
                                        <td className='text-center'>{u.convenioHistorial.convenio.cliente.nombre + " " + u.convenioHistorial.convenio.cliente.apellido}</td>
                                        <td className='text-center'>{u.convenioHistorial.abono}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={4} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                                        <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                                    </td>
                                </tr>
                            )}
                            <tr className='text-start border-bottom align-middle'>
                                <td className='text-center'></td>
                                <td className='text-center'></td>
                            </tr>
                            <tr className='text-start border-bottom align-middle'>
                                <td className='text-center'>Total:</td>
                                <td className='text-center'>${totalAbono.toFixed(2)}</td>
                            </tr>
                        </tbody>

                    </table>
                    <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
                </div>
                <div className="grupo-botones">
                    <div className="divbtn">
                        {/* <button className="btn btn-success mx-1 boton-modal" type='button'>Agregar</button> */}
                        <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HistorialCajaChica;

import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import '../modal_abonos/Modal_abonos.css';
import axios from "axios"; // Importa axios
import { NotificationManager } from "react-notifications";
import jwt_decode from 'jwt-decode';

const ModalAbono = (props) => {
    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
    }, []);

    // Decodificar el token para obtener el ID
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            const decodedToken = jwt_decode(token);
            setUserId(decodedToken.id);
            console.log('ID del usuario:', decodedToken.id);
        }
    }, []);

    const { show, cobranzaId, onAbonoAdded } = props;
    console.log('cobranzaId recibido como prop:', cobranzaId);
    const close = props.close;

    const {
        formState: { errors },
        register,
    } = useForm();

    // Obtener la fecha actual
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        setCurrentDate(formattedDate);
    }, []);

    // Completar campos
    const [userData, setUserData] = useState(null);
    const fetchGroupData = async () => {
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            };

            const response = await axios.get(`${process.env.REACT_APP_API_URL}convenios/${cobranzaId}`, headers);
            console.log("useEffect: ", response);
            setUserData(response.data);
        } catch (error) {
            console.error('Error al obtener los datos del grupo:', error);
        }
    };

    useEffect(() => {
        register('abono', { required: true });
        register('saldo_restante', { required: true });
    }, [register]);

    useEffect(() => {
        if (show && cobranzaId) {
            fetchGroupData();
        }
    }, [show, cobranzaId]);

    useEffect(() => {
        if (userData) {
            setInputValues({
                convenio_id: userData.convenio_id,
                usuario_cobrador: userId, //Asignamos en este caso el ID del usuario con sesion iniciada
                nombre: userData.cliente.nombre,
                apellido: userData.cliente.apellido,
                fecha: currentDate,
                total: userData.saldo_restante,
                abono_esperado: userData.cantidad_abono,
                abono: userData.abono,
                saldo: userData.saldo,
                latitud: userData.latitud,
                longitud: userData.longitud,
                metodo_pago: '',
                cantidad_otorgada: '',
                reciduo: '',
            });
        }
    }, [userData, cobranzaId]);

    const [inputValues, setInputValues] = useState({
        convenio_id: '',
        fecha: '',
        total: '',
        abono_esperado: '',
        abono: '',
        saldo: '',
        usuario_cobrador: '',
        latitud: '',
        longitud: '',
        metodo_pago: '',
        cantidad_otorgada: '',
        reciduo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleChangeSaldoRestante = (e) => {
        const { name, value } = e.target;
        let newAbono = parseFloat(value); // Convierte el valor del abono a número flotante
        let newSaldo = parseFloat(inputValues.total) - newAbono; // Calcula el nuevo saldo restando el abono del total
        newSaldo = Math.max(0, newSaldo); // Asegura que el saldo no sea negativo

        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value, // Actualiza el valor del abono
            saldo: newSaldo.toFixed(2) // Actualiza el saldo restante con dos decimales
        }));
    };

    const handleAdd = async () => {
        try {
            // Elimina el campo "abono_esperado" del objeto "inputValues"
            const { abono_esperado, nombre, apellido, ...dataToSend } = inputValues;

            const headers = {
                headers: {
                    //"Content-Type": "application/x-www-form-urlencoded",
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            };
            // Realiza la solicitud POST a la API
            console.log("data", dataToSend);
            console.log("header", headers);
            console.log("Token:", token);
            await axios.post(process.env.REACT_APP_API_URL + 'convenios/abono', dataToSend, headers);
            NotificationManager.success('Abono agregado exitosamente');

            setInputValues({
                convenio_id: '',
                fecha: '',
                total: '',
                abono_esperado: '',
                abono: '',
                saldo: '',
                usuario_cobrador: '',
                latitud: '',
                longitud: '',
                metodo_pago: '',
                cantidad_otorgada: '',
                reciduo: '',
            });

            close();
            // Después de agregar el abono exitosamente, dispara el evento personalizado
            const event = new Event('abonoAgregado');
            window.dispatchEvent(event);

            // Después de agregar el abono exitosamente, llamamos a la función de callback
            if (onAbonoAdded) {
                onAbonoAdded();
            }

        } catch (error) {
            console.error('Error al crear el abono:', error);
            NotificationManager.error('Error al crear el abono');
        }
    };

    const handleCancel = () => {
        // Vaciar los campos de entrada al cancelar
        setInputValues({
            convenio_id: '',
            fecha: '',
            total: '',
            abono_esperado: '',
            abono: '',
            saldo: '',
            usuario_cobrador: '',
            latitud: '',
            longitud: '',
            metodo_pago: '',
            cantidad_otorgada: '',
            reciduo: '',
        });

        close();
    };
    
    const handlePrint = () => {
        const printDocument = document.createElement('div');
        printDocument.innerHTML = `
            <html>
                <head>
                    <title>Ticket de Compra</title>
                    <style>
                        @media print {
                            @page {
                                size: 67mm 70mm;
                                margin: 0;
                                margin-top: -350px;
                                margin-left: -5px;
                            }
                            .center-text {
                                text-align: center;
                            }
                            .datosPrecio{
                                text-align: right;
                            }
                            .company-info {
                                font-weight: bold;
                                font-size: 16px;
                            }
                            .line {
                                border-top: 2px solid black;
                                margin: 10px 0;
                            }
                            .table {
                                width: 100%;
                                border-collapse: collapse;
                                margin-top: 20px;
                                margin-bottom: 20px;
                            }
                            .table th, .table td {
                                border: 1px solid black;
                                padding: 5px;
                            }
                            .bold{
                                font-weight: bold;
                            }
                        }
                    </style>
                </head>
                <body>
                    <h1 class="center-text">Optica Vision Natural</h1>
                    <p class="center-text company-info">Cel: 6624268604</p>
                    <p class="center-text company-info">Hermosillo, Son.</p>
                    <div class="line"></div>
    
                    <p><span class="bold">Cobrador:</span> Carlos Eden Bustamante Figueroa</p>
                    <p><span class="bold">Cliente:</span> Marco Antonio Davila Torrecillas</p>
                    <div class="line"></div>

                    <table class="table">
                        <thead>
                            <tr>
                                <th>Artículo</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Lentes de sol</td>
                                <td>1</td>
                                <td>$50.00</td>
                            </tr>
                            <tr>
                                <td>Lentes de lectura</td>
                                <td>2</td>
                                <td>$30.00</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="line"></div>
                    <p class="datosPrecio"><span class="bold">Fecha:</span> ${inputValues.fecha}</p>
                    <p class="datosPrecio"><span class="bold">Deuda actual:</span> $${inputValues.total}</p>
                    <p class="datosPrecio"><span class="bold">Abono:</span> $${inputValues.abono}</p>
                    <p class="datosPrecio"><span class="bold">Saldo restante:</span> $${inputValues.saldo}</p>

                </body>
            </html>
        `;

        const printWindow = window.open('', '_blank');
        printWindow.document.write(printDocument.innerHTML);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    // Effect to enable/disable and clear fields based on metodo_pago
    useEffect(() => {
        if (inputValues.metodo_pago !== 'efectivo') {
            setInputValues((prevValues) => ({
                ...prevValues,
                cantidad_otorgada: '',
                reciduo: '',
            }));
        }
    }, [inputValues.metodo_pago]);

    useEffect(() => {
        if (inputValues.metodo_pago === 'efectivo') {
            const cantidad_otorgada = parseFloat(inputValues.cantidad_otorgada) || 0;
            const abono = parseFloat(inputValues.abono) || 0;
            const reciduo = cantidad_otorgada - abono;
            setInputValues((prevValues) => ({
                ...prevValues,
                reciduo: reciduo >= 0 ? reciduo.toFixed(2) : '0.00', // Asegura que el reciduo no sea negativo
            }));
        }
    }, [inputValues.cantidad_otorgada, inputValues.abono]);


    return (
        <div className="modal-containerAbono" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-backgroundAbono" onClick={close}></div>
            <div className="modal-wrapperAbono">
                <div className="headerFormularioAbono">
                    <h3>Generar abono</h3>
                </div>
                <div className='divNombreCliente'>
                    <p style={{ margin: '0px' }}>
                        <strong>Cliente:</strong> {inputValues.nombre} {inputValues.apellido}
                    </p>
                </div>
                <div className="formularioAbono1">
                    <div className="form-group">
                        <label htmlFor="fecha">Fecha:</label><br />
                        <input id="fecha" {...register("fecha")} value={inputValues.fecha} onChange={handleChange} placeholder="Fecha" type='date' disabled />
                        {errors.fecha && <p style={{ color: 'red' }}>La fecha es requerido</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="total">Deuda Actual:</label><br />
                        <input id="total" {...register("total")} value={inputValues.total} onChange={handleChange} disabled />
                        {errors.total && <p style={{ color: 'red' }}>La deuda actual es requerido</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="abono_esperado">Abono esperado:</label><br />
                        <input id="abono_esperado" {...register("abono_esperado")} value={inputValues.abono_esperado} disabled />
                        {errors.abono_esperado && <p style={{ color: 'red' }}>El abono esperado es requerido</p>}
                    </div>
                </div>

                <div className="formularioAbono1">
                    <div className="form-group">
                        <label htmlFor="rol">Tipo de pago:</label><br />
                        <select id="metodo_pago" {...register("metodo_pago")} value={inputValues.metodo_pago} onChange={handleChange}>
                            <option value="" disabled>Seleccionar tipo de pago</option>
                            <option value="efectivo">Efectivo</option>
                            <option value="tarjeta" disabled>Tarjeta</option>
                            <option value="transferencia" disabled>Transferencia</option>
                        </select>
                        {errors.rol && <p style={{ color: 'red' }}>El tipo de pago es requerido</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="cantidad_otorgada">Cantidad recibida:</label><br />
                        <input id="cantidad_otorgada" {...register("cantidad_otorgada")} value={inputValues.cantidad_otorgada} onChange={handleChange} disabled={inputValues.metodo_pago !== 'efectivo'} placeholder="Dinero recibido" />
                        {errors.cantidad_otorgada && <p style={{ color: 'red' }}>La cantidad recibida es requerida</p>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="abono">Abono:</label><br />
                        <input id="abono" name="abono" type='number' {...register("abono")} value={inputValues.abono} onChange={handleChangeSaldoRestante} placeholder="Abono del cliente" />
                        {errors.abono && <p style={{ color: 'red' }}>El abono es requerido</p>}
                    </div>
                </div>
                <div className="formularioAbono2">
                    <div className="form-group">
                        <label htmlFor="reciduo">Cambio:</label><br />
                        <input id="reciduo" {...register("reciduo")} type='number' value={inputValues.reciduo} placeholder="Cambio del cliente" disabled />
                        {errors.reciduo && <p style={{ color: 'red' }}>El cambio es requerido</p>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="saldo">Saldo restante:</label><br />
                        <input id="saldo" {...register("saldo")} value={inputValues.saldo} onChange={handleChange} placeholder="Saldo restante del cliente" disabled />
                        {errors.saldo && <p style={{ color: 'red' }}>El saldo restante es requerido</p>}
                    </div>

                    <div className="form-group" style={{ display: 'none' }}>
                        <label htmlFor="latitud">Latitud:</label><br />
                        <input id="latitud" name="latitud" {...register("latitud")} value={inputValues.latitud} onChange={handleChange} disabled />
                        {errors.latitud && <p style={{ color: 'red' }}>La latitud es requerida</p>}
                    </div>

                    <div className="form-group" style={{ display: 'none' }}>
                        <label htmlFor="longitud">Longitud:</label><br />
                        <input id="longitud" {...register("longitud")} value={inputValues.longitud} onChange={handleChange} disabled />
                        {errors.longitud && <p style={{ color: 'red' }}>La longitud es requerida</p>}
                    </div>

                    <div className="form-group" style={{ display: 'none' }}>
                        <label htmlFor="usuario_cobrador">Usuario cobrador:</label><br />
                        <input id="usuario_cobrador" {...register("usuario_cobrador")} value={inputValues.usuario_cobrador} onChange={handleChange} disabled />
                        {errors.usuario_cobrador && <p style={{ color: 'red' }}>El cobrador es necesario</p>}
                    </div>

                    <div className="form-group" style={{ display: 'none' }}>
                        <label htmlFor="convenio_id">ID Convenio:</label><br />
                        <input id="convenio_id" {...register("convenio_id")} value={inputValues.convenio_id} onChange={handleChange} disabled />
                        {errors.convenio_id && <p style={{ color: 'red' }}>El id del convenio es necesario</p>}
                    </div>
                </div>
                <div className="modal-botonesAbono">
                    <div className="divbtnAbono">
                        <button className="btn btn-success mx-1 boton-modal" type='button' onClick={handleAdd}>Abonar</button>
                       {/*  <button className="btn btn-primary mx-1 boton-modal" type='button' onClick={handlePrint}>Imprimir</button> */}
                        <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}>Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAbono;

import React, { useState, useEffect } from 'react';
import style from './ConveniosPage.module.sass';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Importa SweetAlert
import { MdOutlineContactPage, } from 'react-icons/md';
import axiosConfig from '../../../utils/axiosConfig';
import axios from "axios"; // Importa axios
import { NotificationManager } from 'react-notifications';
import { FaTrash, FaPencilAlt, FaCheck, FaHistory, FaCashRegister, FaMotorcycle } from 'react-icons/fa';
import { Pagination } from '../../../components/pagination/Pagination';
import { Filter } from '../../../components/filter/Filter';
import { BiSpreadsheet } from "react-icons/bi";
import ModalAbono from '../../../components/modal_convenios/modal_abonos/Modal_abonos';


import ModalCobradores from '../../../components/modal_convenios/modal_cobrador/Modal_cobrador';


const initialFilter = {
  search: null,
  status: 'En Proceso', // Modificar para usar "En proceso"
  order: 'desc',
  limit: 10,
  page: 1,
  cobrador: 0
};

const ConveniosPage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const [lUsers, setLUsers] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalCobradoresShow, setModalCobradoresShow] = useState(false);

  const [searchInput, setSearchInput] = useState(null);

  const [searchBtn, setSearchBtn] = useState(false);
  const [activeFilter, setActiveFilter] = useState('En Proceso');

  const [filter, setFilter] = useState(initialFilter);
  const mensajeVacio = '';

  const [refreshUsers, setRefreshUsers] = useState(false);

  const [detailUser, setDetailUser] = useState({});

  // Función para abrir el modal y establecer el ID del grupo seleccionado
  const [convenioId, setConvenioId] = useState(null);

  const openModalHandler = (cobranzaId) => {
    setConvenioId(cobranzaId);
    setModalShow(true);
    console.log('convenioId:', cobranzaId);
  };

  useEffect(() => {
    if (refreshUsers) {
      getUser();
    }
    setRefreshUsers(false);
  }, [refreshUsers]);

  useEffect(() => {
    setFilter(() => {
      return { ...filter, status: activeFilter }; // Ajustar el filtro según el estado
    });
    setRefreshUsers(true);
  }, [activeFilter]);

  useEffect(() => {
    if (searchBtn && searchInput != null) {
      setFilter(() => {
        return { ...filter, search: searchInput };
      });
      setRefreshUsers(true);
      setSearchBtn(false);
    }
  }, [searchBtn]);

  const [pageCount, setPageCount] = useState(0);

  const getUser = () => {
    axiosConfig({
      method: 'GET',
      url: '/convenios',
      params: { ...filter },
    })
      .then((result) => {
        console.log("Carga de datos: ", result);

        // Asegúrate de que los datos devueltos están en el formato esperado
        setLUsers(result.rows);
        setPageCount(Math.ceil(result.count / 10));
      })
      .catch((err) => console.log(err));
  };

  const handlePageClick = (event) => {
    setFilter(() => {
      return { ...filter, page: event.selected + 1 };
    });
    setRefreshUsers(true);
  };

  const closeModalHandler = () => {
    setModalShow(false);
  };

  const closeModalCobreadoresHandler = () => {
    setModalCobradoresShow(false);
  };

  const openModalCobradoresHandler = (e) => {
    setModalCobradoresShow(true);
  };

  const onchangeOptions = (e) => {
    axiosConfig({
      method: 'put',
      url: `/users/activo/${e.user_id}`,
    })
      .then(() => {
        NotificationManager.success(
          !e.active ? 'Activated with success' : 'Activated with success',
          'Success!',
          2500
        );
        setRefreshUsers(true);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.success('Could not change state', 'Error!');
      })
      .finally(() => {
        closeModalHandler();
        setRefreshUsers(true);
      });
  };

  const cambioActivo = async (convenio_id, data, actionType) => {
    try {
      let confirmationMessage1 = '';
      let confirmationMessage2 = ''; // Inicializa el mensaje de confirmación

      // Define el mensaje de confirmación en función del tipo de acción
      if (actionType === 'desactivar') {
        confirmationMessage1 = 'Desactivar Usuario';
        confirmationMessage2 = '¿Estás seguro de que deseas desactivarlo?';
      } else if (actionType === 'activar') {
        confirmationMessage1 = 'Activar Usuario';
        confirmationMessage2 = '¿Estás seguro de que deseas activarlo?';
      }

      const confirmed = await Swal.fire({ // Utiliza Swal.fire para mostrar SweetAlert
        title: confirmationMessage1,
        text: confirmationMessage2,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, realizar acción',
        cancelButtonText: 'Cancelar',
      });

      if (!confirmed.isConfirmed) return; // Si el usuario cancela, no hacer nada

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };

      // Realiza la solicitud PUT a la API para actualizar el estado del usuario
      await axios.put(`${process.env.REACT_APP_API_URL}convenios/estatus/${convenio_id}`, data, headers);

      getUser();

      NotificationManager.success('Estado de la cobranza actualizado exitosamente');

      // Redirige a la página deseada
    } catch (error) {
      console.error('Error al actualizar el estado de la cobranza:', error);
      NotificationManager.error('Error al actualizar el estado de la cobranza');
    }
  };

  useEffect(() => {
    const abonoAgregadoHandler = () => {
      getUser();
    };

    window.addEventListener('abonoAgregado', abonoAgregadoHandler);

    return () => {
      window.removeEventListener('abonoAgregado', abonoAgregadoHandler);
    };
  }, []);

  const [daysDifference, setdaysDifference] = useState(-1);
  const [color, setColor] = useState('red');

  const calculateDaysDifference = (date) => {
    const [day, month, year] = date.split('/').map(Number);
    const targetDate = new Date(year, month - 1, day); // Mes en el constructor Date es 0-indexed
    targetDate.setHours(0, 0, 0, 0); // Ajusta la hora a medianoche

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ajusta la hora a medianoche

    const differenceInTime = today - targetDate;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays <= 0) {
      return 'green';
    } else if (differenceInDays <= 3) {
      return 'orange';
    } else {
      return 'red';
    }
  };

  return (
    <div className={`${style.ConveniosPage}`}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <MdOutlineContactPage
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ 'mixBlendMode': 'difference' }}>Cobranza</span>
        </h3>
      </div>


      <Filter
        setActiveFilter={setActiveFilter}
        setSearchInput={setSearchInput}
        setSearchBtn={setSearchBtn}
        setModulo='cobranza'
        filter={filter}
        setFilter={setFilter}
        setRefreshUsers={setRefreshUsers}
      />

      <div className={`${style.table} table-responsive bg-white`}>
        <table className='table table-borderless border-bottom'>
          <thead>
            <tr className='text-start border-bottom'>
              <th className='text-center'>Nombre</th>
              <th className='text-center'>Ultimo abono</th>
              <th className='text-center'>Siguiente Abono</th>
              <th className='text-center'>Total</th>
              <th className='text-center'>Saldo restante</th>
              <th className='text-center'>Estatus</th>
              {/*<th className='text-center'>Cobrador</th>*/}
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lUsers.length > 0 &&
              lUsers.map((u) => (
                <tr className='text-start border-bottom align-middle' key={u.convenio_id}>
                  <td className='text-center'>{u.cliente != null ? u.cliente.nombre + ' ' + u.cliente.apellido : ''}</td>
                  <td className='text-center'>{u.fecha_ultimo_abono ? new Date(u.fecha_ultimo_abono).toLocaleDateString('es-ES', { timeZone: 'UTC' }) : ''}</td>
                  <td className='text-center'>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: calculateDaysDifference(new Date(u.fecha_siguiente_abono).toLocaleDateString('es-ES', { timeZone: 'UTC' })),
                        marginRight: '10px',
                        marginTop: '10px',
                      }}
                    ></span>
                    {new Date(u.fecha_siguiente_abono).toLocaleDateString('es-ES', { timeZone: 'UTC' })}</td>
                  <td className='text-center'>${u.total}</td>
                  <td className='text-center'>${u.saldo_restante}</td>
                  <td className='text-center'>
                    {(u.estatus == 'En proceso') ? (
                      <span className='badge bg-success rounded-pill'>En proceso</span>
                    ) : (
                      <span className='badge bg-danger rounded-pill'>Finalizado</span>
                    )}
                  </td>
                  {/*<td className='text-center'>{u.cobrador != null ? u.cobrador.nombre + ' ' + u.cobrador.apellido : ''}</td>*/}
                  <td className={`text-center ${style['accionesWidth']}`}>
                    <button
                      id='btnEdit'
                      className='btn btn-sm btn-warning mx-1'
                      onClick={() => navigate(`/panel/cobranza/updateCobranza/${u.convenio_id}`)}
                    >
                      <FaPencilAlt size={25} color='white' />
                    </button>

                    {/*<button
                      id='btnEdit'
                      className='btn btn-sm btn-warning mx-1'
                      style={{ backgroundColor: 'green', borderColor: 'green' }}
                      onClick={openModalCobradoresHandler}
                    >
                      <FaMotorcycle size={25} color='white' />
                  </button>*/}
                    <button
                      id='btnEdit'
                      className='btn btn-sm btn mx-1'
                      style={{ backgroundColor: 'brown', borderColor: 'brown' }}
                      onClick={() => {
                        openModalHandler(u.convenio_id)
                        console.log(u.convenio_id);
                      }}>
                      <FaCashRegister size={25} color='white' />
                    </button>
                    <button
                      id='btnEdit'
                      className='btn btn-sm btn-warning mx-1'
                      style={{ backgroundColor: 'grey', borderColor: 'grey' }}
                      onClick={() => navigate(`/panel/cobranza/historial/${u.convenio_id}`)}
                    >
                      <BiSpreadsheet size={25} color='white' />
                    </button>
                  </td>
                </tr>
              ))}
            {lUsers.length === 0 && (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                  <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>

      <ModalAbono
        className='modal'
        show={modalShow}
        setModalShow={setModalShow}
        close={closeModalHandler}

        cobranzaId={convenioId}
        onAbonoAdded={getUser}
      ></ModalAbono>

      <ModalCobradores
        className='modal'
        show={modalCobradoresShow}
        setModalShow={setModalCobradoresShow}
        close={closeModalCobreadoresHandler}
      ></ModalCobradores>
    </div>
  );
};

export default ConveniosPage;

import React, { useState, useEffect } from 'react';
import style from './HistorialCobranzaPage.module.sass';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineContactPage } from 'react-icons/md';
import axiosConfig from '../../../utils/axiosConfig';
import { Pagination } from '../../../components/pagination/Pagination';
import { FaCashRegister } from "react-icons/fa6";
import { TbCashBanknoteOff } from "react-icons/tb";
import { FaRegListAlt } from "react-icons/fa";
import ModalCerrarCajaChica from "../../../components/cobradores/cerrar_caja_chica/modalCerrarCajaChica";
import HistorialCajaChica from '../../../components/cobradores/historialCaja/historialCajaChica';

import { MdArrowBackIosNew } from 'react-icons/md';
import moment from "moment-timezone";

const initialFilter = {
  cobrador_id: 0,
  order: 'desc',
  limit: 10,
  page: 1,
};

const HistorialCobranzaPage = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Obtiene el usuario_id de la URL
  const [token, setToken] = useState(null);
  const [lUsers, setLUsers] = useState([]);
  const [filter, setFilter] = useState({ ...initialFilter, cobrador_id: id });
  const mensajeVacio = '';
  const [refreshUsers, setRefreshUsers] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [cobradores, setCobradores] = useState({}); // Objeto para almacenar nombres y apellidos de cobradores

  const backlUser = () => {
    navigate("/panel/cobradores");
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
    // Llamar a la función getHistorial al cargar la página
    getHistorial(id);
  }, [id]); // El useEffect se ejecutará cada vez que cambie el id

  useEffect(() => {
    if (refreshUsers) {
      getHistorial(id);
    }
    setRefreshUsers(false);
  }, [refreshUsers, id]); // Asegúrate de que se vuelva a cargar al cambiar refreshUsers o id

  const getHistorial = (userId) => {
    axiosConfig({
      method: 'GET',
      url: `/cobrador/cajasChicas`, // Utiliza el userId recibido
      params: { ...filter },
    })
      .then((result) => {
        console.log("Carga de datos: ", result);
        setLUsers(result.rows);
        setPageCount(Math.ceil(result.count / 10));
      })
      .catch((err) => console.log(err));
  };

  const handlePageClick = (event) => {
    setFilter(() => {
      return { ...filter, page: event.selected + 1 };
    });
    setRefreshUsers(true);
  };

  // CODIGO PARA MODAL CERRAR CAJA CHICA
  const [dataReferenciaCCH, setDataReferenciasCCH] = useState([]);
  const [modalShowCCH, setModalShowCCH] = useState(false);
  const closeModalHandlerCCH = () => {
    setModalShowCCH(false);
  };

  const [cajaChicaId, setCajaChicaId] = useState(null);

  const openModalHandlerCCH = (cajachica_id) => {
    setCajaChicaId(cajachica_id);
    setModalShowCCH(true);
    console.log('cajachica_id:', cajachica_id);
  };
  //

  // CODIGO PARA MODAL CERRAR CAJA CHICA
  const [dataReferenciaHistorial, setDataReferenciasHistorial] = useState([]);
  const [modalShowHistorial, setModalShowHistorial] = useState(false);
  const closeModalHandlerHistorial = () => {
    setModalShowHistorial(false);
  };

  //const [cajaChicaId, setCajaChicaId] = useState(null);

  const openModalHandlerHistorial = (cajachica_id) => {
    setCajaChicaId(cajachica_id);
    setModalShowHistorial(true);
    console.log('cajachica_id:', cajachica_id);
  };
  //

  useEffect(() => {
    const handleGrupoAgregado = () => {
      getHistorial(); // Llama a la función para actualizar la tabla
    };
    const handleGrupoEditado = () => {
      getHistorial(); // Llama a la función para actualizar la tabla
    };
    // Agrega el event listener para el evento personalizado
    window.addEventListener('grupoAgregado', handleGrupoAgregado);
    window.addEventListener('grupoEditado', handleGrupoEditado);
    // Limpia el event listener cuando el componente se desmonte para evitar fugas de memoria
    return () => {
      window.removeEventListener('grupoAgregado', handleGrupoAgregado);
      window.removeEventListener('grupoEditado', handleGrupoEditado);
    };
  }, []);

  const formatTimeTo12Hour = (timeStr) => {
    const [hour, minute, second] = timeStr.split(':');
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? 'p. m.' : 'a. m.';
    const hour12 = hourInt % 12 || 12; // Convierte 0 a 12 para el formato de 12 horas
    return `${hour12}:${minute} ${ampm}`;
  };

  return (
    <div className={`${style.ConveniosPage}`}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <FaCashRegister
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ 'mix-blend-mode': 'difference' }}>Caja Chica</span>
        </h3>
      </div>

      <div className={`${style.boxHistorial} bg-white`}>
        <div className={`${style.boxBarraHistorial} fw-normal p-2 border-secondary`}>
          <h3 className='h3 fw-bold' style={{ fontSize: 20 + 'px' }}>
            <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer', marginRight: '10px' }} />
          </h3>
        </div>
      </div>

      <div className={`${style.table} table-responsive bg-white`}>
        <table className='table table-borderless border-bottom'>
          <thead>
            <tr className='text-start border-bottom'>
              <th className='text-center'>Estatus</th>
              <th className='text-center'>Fecha Inicial</th>
              <th className='text-center'>Fecha Final</th>
              <th className='text-center'>Hora Inicial</th>
              <th className='text-center'>Hora Final</th>
              <th className='text-center'>Monto Inicial</th>
              <th className='text-center'>Monto Final</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lUsers.length > 0 &&
              lUsers.map((u) => (
                <tr className='text-start border-bottom align-middle' key={u.historial_id}>
                  <td className='text-center'>{u.estatus}</td>
                  <td className='text-center'>{u.fecha_inicial}</td>
                  <td className='text-center'>{u.fecha_cierre}</td>
                  <td className="text-center">
                    {u.hora_inicial ? formatTimeTo12Hour(u.hora_inicial) : ''}
                  </td>
                  <td className="text-center">
                    {u.hora_cierre ? formatTimeTo12Hour(u.hora_cierre) : ''}
                  </td>
                  <td className='text-center'>${u.monto_inicial ? u.monto_inicial : 0}</td>
                  <td className='text-center'>${u.monto_final ? u.monto_final : 0}</td>
                  <td className='text-center'>
                    <button
                      id='btnEliminarCCH'
                      className='btn btn-sm btn-danger mx-1'
                      disabled={u.estatus === 'Cerrada'}
                      onClick={() => {
                        openModalHandlerCCH(u.cajachica_id)
                        console.log(u.cajachica_id);
                      }}>
                      <TbCashBanknoteOff size={25} color='white' />
                    </button>
                    <button
                      id='btnHistorialCCH'
                      className='btn btn-sm btn-secondary mx-1'
                      onClick={() => {
                        openModalHandlerHistorial(u.cajachica_id)
                        console.log(u.cajachica_id);
                      }}>
                      <FaRegListAlt size={25} color='white' />
                    </button>
                  </td>
                </tr>
              ))}
            {lUsers.length === 0 && (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                  <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>
      <ModalCerrarCajaChica
        className='modal'
        show={modalShowCCH}
        setModalShow={setModalShowCCH}
        close={closeModalHandlerCCH}
        setDataReferencias={setDataReferenciasCCH}
        data={dataReferenciaCCH}
        cajachica_id={cajaChicaId}
      ></ModalCerrarCajaChica>

      <HistorialCajaChica
        className='modal'
        show={modalShowHistorial}
        setModalShow={setModalShowHistorial}
        close={closeModalHandlerHistorial}
        setDataReferencias={setDataReferenciasHistorial}
        data={dataReferenciaHistorial}
        cajachica_id={cajaChicaId}
      ></HistorialCajaChica>
    </div>
  );
};

export default HistorialCobranzaPage;

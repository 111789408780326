import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import style from './DetailUserPage.module.sass'

import { MdArrowBackIosNew } from 'react-icons/md'
import { FiEdit, FiPower } from 'react-icons/fi'
import axiosConfig from '../../../utils/axiosConfig'

import { NotificationManager } from 'react-notifications'
import { AiFillEye } from 'react-icons/ai';

const DetailUserPage = () => {
  const navigate = useNavigate()
  const [lUsers, setLUsers] = useState([]);
  const backlUser = () => {
    navigate("/panel/users");
  }
  return (
    <div className={`${style.DetailUserPage}`}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <span style={{ 'mix-blend-mode': 'difference' }}>Usuarios</span>
        </h3>

      </div>

      <div className={`${style.box} bg-white`}>
        <div className='fw-normal p-3 border-secondary'>
          <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer' }} />
          Detalle de Usuario
        </div>


      </div>
    </div>
  );
}

export default DetailUserPage
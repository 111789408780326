import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import '../modal_agregar/Modal_TipoCliente.css';
import axios from "axios"; // Importa axios
import { NotificationManager } from "react-notifications";

const ModalTipoClientesEditar = (props) => {
    const { show, typeclienteId } = props;
    console.log('typeclienteId recibido como prop:', typeclienteId);
    const close = props.close;

    const {
        formState: { errors },
        register,
        setValue,
    } = useForm();

    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
    }, []);

    useEffect(() => {
        // Define las reglas de validación utilizando la librería react-hook-form
        register('nombre', { required: true });
        register('descripcion', { required: true });
    }, [register]);

    const [inputValues, setInputValues] = useState({
        nombre: '',
        descripcion: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const [userData, setUserData] = useState(null);
    const fetchGroupData = async () => {
        try {
            const headers = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            };

            const response = await axios.get(`${process.env.REACT_APP_API_URL}tipocliente/${typeclienteId}`, headers);
            console.log("useEffect: ", response);
            setUserData(response.data);
        } catch (error) {
            console.error('Error al obtener los datos del tipoCliente:', error);
        }
    };

    useEffect(() => {
        if (show && typeclienteId) {
            fetchGroupData();
        }
    }, [show, typeclienteId]);

    useEffect(() => {
        if (userData) {
            setInputValues({
                nombre: userData.nombre,
                descripcion: userData.descripcion
            });
        }
    }, [userData, typeclienteId]);

    //modal de edicion
    const handleEdit = async () => {
        try {
            const headers = {
                headers: {
                    //"Content-Type": "application/x-www-form-urlencoded",
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            };
            // Realiza la solicitud PUT a la API
            console.log("data", inputValues);
            console.log("header", headers);
            await axios.put(`${process.env.REACT_APP_API_URL}tipocliente/${typeclienteId}`, inputValues, headers);
            NotificationManager.success('Tipo de cliente modificado exitosamente');

            setInputValues({
                nombre: '',
                descripcion: ''
            });

            close();
            // Después de agregar el tipoCliente exitosamente, dispara el evento personalizado
            const event = new Event('tipoClienteEditado');
            window.dispatchEvent(event);
        } catch (error) {
            console.error('Error al modificar el tipo de cliente:', error);
            NotificationManager.error('Error al modificar el tipo de cliente');
        }
    };


    const handleCancel = () => {
        // Vaciar los campos de entrada al cancelar
        setInputValues({
            nombre: '',
            descripcion: ''
        });

        close();
    };

    return (
        <div className="modal-container" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-background" onClick={close}></div>
            {userData && (
                <div className="modal-tipoCliente">
                    <div className="headerFormulario">
                        <h3>Editar tipo de cliente</h3>
                    </div>
                    <div className="formulario">
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre:</label><br />
                            <input
                                type="text"
                                name="nombre"
                                id="nombre"
                                value={inputValues.nombre}
                                onChange={handleChange}
                                placeholder="Nombre del tipo de cliente..."
                            />
                            {errors.nombre && <p style={{ color: 'red' }}>El nombre es requerido</p>}
                        </div>

                        <div className="form-group">
                            <label htmlFor="descripcion">Descripcion:</label><br />
                            <input
                                type="text"
                                name="descripcion"
                                id="descripcion"
                                value={inputValues.descripcion}
                                onChange={handleChange}
                                placeholder="Descripcion del tipo de cliente..."
                            />
                            {errors.descripcion && <p style={{ color: 'red' }}>El apellido es requerido</p>}
                        </div>
                    </div>
                    <div className="tipoCliente-botones">
                        <div className="divbtn">
                            <button className="btn btn-success mx-1 boton-modal" type='button' onClick={handleEdit}>Editar</button>
                            <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}> Cancel </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ModalTipoClientesEditar;

import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Dashboard from "./pages/dashboard/Dashboard";
import Layout from "./pages/layout/Layout";


import LoginPage from "./pages/moduloLogin/login/LoginPage"
import ForgottenPasswordPage from './pages/moduloLogin/forgottenPassword/forgottenPasswordPage';
import PasswordChangePage from "./pages/moduloLogin/PasswordChange/PasswordChangePage"
import PasswordChangeSuccessPage from './pages/moduloLogin/passwordChangeSuccess/PasswordChangeSuccessPage'

import UsersPage from './pages/moduloUsuario/users/UsersPage';
import CreateUserPage from './pages/moduloUsuario/createUser/CreateUserPage';
import DetailUserPage from './pages/moduloUsuario/detailUser/DetailUserPage';
import UpdateUser from './pages/moduloUsuario/updateUser/UpdateUserPage';

import ClientesPage from './pages/moduloClientes/clientes/ClientesPage';
import CreateClientesPage from './pages/moduloClientes/createCliente/CreateClientePage'
import UpdateClientePage from './pages/moduloClientes/updateCliente/UpdateClientePage';

import ConveniosPage from './pages/moduloConvenios/convenios/ConveniosPage';
import CreateConveniosPage from './pages/moduloConvenios/createConvenios/CreateConveniosPage';
import UpdateConveniosPage from "./pages/moduloConvenios/updateConvenios/UpdateConveniosPage";
import HistorialPage from './pages/moduloConvenios/historial/HistorialPage';

import CobradoresPage from './pages/moduloCobradores/cobradores/CobradoresPage';
import HistorialCobranzaPage from "./pages/moduloCobradores/historialCobranza/HistorialCobranzaPage";

import GruposPage from './pages/moduloGrupo/grupos/GruposPage';

import TipoClientesPage from './pages/moduloTipoCliente/TipoClientesPage';



function App() {

	return (
		<BrowserRouter>
			<Routes>

				<Route path="/panel" element={<Layout />}>
					<Route index path="/panel" element={<Dashboard />} />
					<Route exact path="/panel/users" element={<UsersPage />} />
					<Route exact path="/panel/users/crear" element={<CreateUserPage />} />
					<Route exact path="/panel/users/:id" element={<DetailUserPage />} />
					<Route exact path="/panel/users/up/:id" element={<UpdateUser />} />

					<Route exact path="/panel/clientes" element={<ClientesPage />} />
					<Route exact path="/panel/clientes/crear" element={<CreateClientesPage />} />
					<Route exact path="/panel/clientes/updateCliente/:id" element={<UpdateClientePage />} />

					<Route exact path="/panel/cobranza" element={<ConveniosPage />} />
					<Route exact path="/panel/cobranza/crear" element={<CreateConveniosPage />} />
					<Route exact path="/panel/cobranza/updateCobranza/:id" element={<UpdateConveniosPage />} />
					<Route exact path='/panel/cobranza/historial/:id' element={<HistorialPage />} />

					<Route exact path="/panel/cobradores" element={<CobradoresPage />} />
					<Route exact path='/panel/cobradores/cajaChica/:id' element={<HistorialCobranzaPage />} />

					<Route exact path="/panel/grupo" element={<GruposPage />} />

					<Route exact path="/panel/tipocliente" element={<TipoClientesPage />} />
				</Route>

				<Route path="/forgottenPassword" element={<ForgottenPasswordPage />} />
				<Route path="/login" element={<LoginPage />} />
				<Route path="/passwordChange" element={<PasswordChangePage />} />
				<Route path="/passwordChangeSuccess" element={<PasswordChangeSuccessPage />} />

				<Route path="*" element={<Navigate to="/panel" replace />} />

			</Routes>
		</BrowserRouter>
	);
}

export default App;

import { Outlet, Navigate } from 'react-router-dom';
import style from './Layout.module.sass';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import Sidebar from '../../components/sidebar/Sidebar';
import { getToken } from '../../utils/authService';
import { useState } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const Layout = () => {
  const token = getToken();
  const [open, setOpen] = useState(false);

  if (!token) {
    return <Navigate to='/login' replace={true} />;
  }

  return (
    <>
      <Sidebar open={open} setOpen={setOpen} />
      <div
        className={`${
          open ? style['layout-contenedor-principal2'] : style['layout-contenedor-principal']
        }`}
      >
        <div className={`${open ? style['back2'] : style['back']}`} />
        <main className={style['layout-main']}>
          <Outlet />
        </main>
      </div>
      <NotificationContainer />
    </>
  );
};

export default Layout;

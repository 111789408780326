import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import { RiHome3Line } from "react-icons/ri";
import axiosConfig from '../../utils/axiosConfig';

const initialFilter = {
  status: [1, 2, 3, 4, 5],
};

const Dashboard = () => {


  return (
    <>
      <div className={'dashboard pb-5'}>
        <div className='d-flex justify-content-between mx-0 px-0 mb-4'>
          <h3 className='h3 fw-bold'>
            <RiHome3Line
              className={'mb-2'}
              color={'white'}
              size={'40'}
              style={{ mixBlendMode: 'difference' }}
            />
            <span style={{ mixBlendMode: 'difference' }}> Dashboard </span>
          </h3>
        </div>

      </div>
    </>
  );
};

export default Dashboard;

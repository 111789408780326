import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import '../caja_chica/modalCajaChica.css';
import axios from "axios"; // Importa axios
import { NotificationManager } from "react-notifications";

const ModalCajaChica = (props) => {
    const { show, cobrador_id } = props;
    console.log('cobrador_id recibido como prop:', cobrador_id);

    const close = props.close;

    const {
        formState: { errors },
        register,
    } = useForm();

    useEffect(() => {
        // Define las reglas de validación utilizando la librería react-hook-form
        register('monto_inicial', { required: true });
    }, [register]);

    const [inputValues, setInputValues] = useState({
        cobrador_id: cobrador_id,
        monto_inicial: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(sessionStorage.getItem('token'));
    }, []);

    const handleAdd = async () => {
        try {
            const headers = {
                headers: {
                    //"Content-Type": "application/x-www-form-urlencoded",
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
            };

            const dataToSend = { ...inputValues, cobrador_id }; // Añadimos cobrador_id a los datos que se enviarán

            // Realiza la solicitud POST a la API
            console.log("data", dataToSend);
            console.log("header", headers);
            await axios.post(process.env.REACT_APP_API_URL + 'cobrador/cajaChica', dataToSend, headers);
            NotificationManager.success('Caja chica agregada exitosamente');

            setInputValues({
                monto_inicial: '',
                cobrador_id: cobrador_id // Reseteamos el estado, asegurando que cobrador_id se mantenga
            });

            close();
            // Después de agregar el grupo exitosamente, dispara el evento personalizado
            const event = new Event('grupoAgregado');
            window.dispatchEvent(event);

        } catch (error) {
            console.error('Error al agregar la caja chica:', error);
            NotificationManager.error('Error al agregar la caja chica, verifique si tiene una activa.');
        }
    };

    const handleCancel = () => {
        // Vaciar los campos de entrada al cancelar
        setInputValues({
            monto_inicial: ''
        });

        close();
    };

    return (
        <div className="modal-containerCCH" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-background" onClick={close}></div>
            <div className="modal-grupo">
                <div className="headerFormulario">
                    <h3>Caja chica</h3>
                </div>
                <div className="formulario">
                    <div className="form-group">
                        <label htmlFor="monto_inicial">Monto:</label><br />
                        <input
                            type="text"
                            name="monto_inicial"
                            id="monto_inicial"
                            value={inputValues.monto_inicial}
                            onChange={handleChange}
                            placeholder="Ingrese el monto para la caja chica"
                        />
                        {errors.monto_inicial && <p style={{ color: 'red' }}>El monto_inicial es requerido</p>}
                    </div>
                </div>
                <div className="grupo-botones">
                    <div className="divbtn">
                        <button className="btn btn-success mx-1 boton-modal" type='button' onClick={handleAdd}>Agregar</button>
                        <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}> Cancel </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCajaChica;

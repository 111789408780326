import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Importa axios
import style from "./CreateClientePage.module.sass";
import { BiUserPlus } from "react-icons/bi";
import { MdArrowBackIosNew } from 'react-icons/md'
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import GoogleMapSearch from '../../../components/maps/Maps';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { FaPlus } from "react-icons/fa";
import DataTable from '../../../components/tabla-clientes/DataTable';
import Modal from '../../../components/modal_DUser/Modal_DUser';

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const CreateClientePage = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm();

  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const backlUser = () => {
    navigate("/panel/clientes");
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const [address, setAddress] = useState('');
  const [latLng, setLatLng] = useState({ lat: 29.1026, lng: -110.97732 });

  const [address2, setAddress2] = useState('');
  const [latLng2, setLatLng2] = useState({ lat: 29.1026, lng: -110.97732 });

  const [lClientes, setLClientes] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const mensajeVacio = '';
  const [filter, setFilter] = useState(initialFilter);
  const [refreshClientes, setRefreshClientes] = useState(false);

  const handlePageClick = (event) => {
    setFilter(() => {
      return { ...filter, page: event.selected + 1 };
    });
    setRefreshClientes(true);
  };

  useEffect(() => {
    setLatLng(latLng);
  }, [latLng]);

  useEffect(() => {
    setLatLng2(latLng2);
  }, [latLng2]);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleCheckboxChange = (option) => {
    if (selectedOption === option) {
      // Si se hace clic en el checkbox ya seleccionado, lo deseleccionamos
      setSelectedOption(null);
    } else {
      // Si se hace clic en un checkbox diferente al seleccionado, lo seleccionamos
      setSelectedOption(option);
    }
  };

  //DataTable
  const [modalShow, setModalShow] = useState(false);
  const closeModalHandler = () => {
    setModalShow(false);
  };

  const openModalHandler = (e) => {
    setModalShow(true);
  };

  const [dataReferencia, setDataReferencias] = useState([]);

  useEffect(() => {
    console.log(dataReferencia);
  }, [dataReferencia]);

  //Obtener tipoClientes
  const [tipoClientes, setTipoClientes] = useState([]);
  const [filterTC, setFilterTC] = useState(initialFilter);

  useEffect(() => {
    const fetchTipoClientes = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filterTC }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}tipocliente`, headers);
        console.log("Respuesta completa de la API:", response.data);
        setTipoClientes(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los tipoClientes:", error);
      }
    };

    if (token) {
      fetchTipoClientes();
    }
  }, [token]);

  const columns = React.useMemo(
    () => [
      { Header: 'Nombre', accessor: 'nombre' },
      { Header: 'Parentesco', accessor: 'parentesco' },
      { Header: 'Direccion', accessor: 'direccion' },
      { Header: 'Telefono', accessor: 'telefono' },
      { Header: 'Aval', accessor: 'aval' },
      { Header: 'Accion', accessor: 'accion' },
    ],
    []
  );

  useEffect(() => {
    // Define las reglas de validación utilizando la librería react-hook-form
    register('nombre', { required: true });
    register('apellido', { required: true });
    register('email', { required: true });
    register('fecha_nacimiento', { required: true });
    register('telefono', { required: true });
    //register('direccion', { required: true });
    register('estado', { required: true });
    register('ciudad', { required: true });
    register('codigo_postal', { required: true });
    register('longitud', { required: true });
    register('latitud', { required: true });
    //register('curp', { required: true });
    //register('trabajador_nombre', { required: true });
    //register('trabajador_empresa', { required: true });
    //register('trabajador_domicilio', { required: true });
    //register('trabajador_entre', { required: true });
    //register('trabajador_colonia', { required: true });
    //register('trabajador_telefono', { required: true });
    //register('trabajador_puesto', { required: true });
    //register('trabajador_antiguedad', { required: true });
    //register('trabajador_relacion', { required: true });
    //register('trabajador_nombre_rel', { required: true });
    //register('trabajador_telefono_rel', { required: true });
    register('cobro_hora', { required: true });
    register('cobro_hora2', { required: true });
  }, [register]);

  const onSubmit = async (data) => {
    try {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };

      let requestData = {
        ...data, direccion: address, trabajador_domicilio: address2,
        latitud: String(latLng.lat), longitud: String(latLng.lng),
        latitud_empresa: String(latLng2.lat), longitud_empresa: String(latLng2.lng),
        cliente_relaciones: dataReferencia
      };

      // Combinar data y address en un nuevo objeto
      console.log("Datos enviados: ", requestData)
      // Realiza la solicitud POST a la API
      await axios.post(process.env.REACT_APP_API_URL + 'clientes', requestData, headers);
      NotificationManager.success('Cliente agregado exitosamente');
      // Redirige a la página deseada
      navigate('/panel/clientes');
    } catch (error) {
      console.error('Error al agregar cliente:', error);
      NotificationManager.error('Error al agregar cliente');
    }
  };


  return (
    <div className={`${style.CreateClientePage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <BiUserPlus
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ mixBlendMode: 'difference' }}>Agregar nuevo cliente</span>
        </h3>
      </div>

      {/* Agrega el formulario para ingresar los datos del usuario */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${style.box} bg-white`}>
          <div className={`${style.boxBarra} fw-normal p-2 border-secondary`}>
            <h3 className='h3 fw-bold' style={{ fontSize: 20 + 'px' }}>
              <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer', marginRight: '10px' }} />
            </h3>
            <button type="submit">
              <BiUserPlus
                color={'white'}
                size={'35'}
              />
            </button>
          </div>
        </div>
        <div className={`${style.contenedor} `}>
          <div className={`${style.formularioCliente} `}>
            <div className={`${style.headerFormulario} `}>
              <h3>Datos del cliente</h3>
            </div>
            <div className={`${style.formulario} `}>
              <div className="form-group">
                <label htmlFor="nombre">Nombre:</label><br />
                <input id="nombre" {...register("nombre")} placeholder="Nombre del cliente" />
                {errors.nombre && <p style={{ color: 'red' }}>El nombre es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="apellido">Apellido:</label><br />
                <input id="apellido" {...register("apellido")} placeholder="Apellido del cliente" />
                {errors.apellido && <p style={{ color: 'red' }}>El apellido es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email:</label><br />
                <input id="email" {...register("email")} placeholder="Email del cliente" />
                {errors.email && <p style={{ color: 'red' }}>El email es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="fecha_nacimiento">Fecha de Nacimiento:</label><br />
                <input id="fecha_nacimiento" type="date" {...register("fecha_nacimiento")} placeholder="Fecha de nacimiento del cliente" />
                {errors.fecha_nacimiento && <p style={{ color: 'red' }}>La fecha de nacimiento es requerida</p>}
              </div>

              <div className="form-group">
                <label htmlFor="telefono">Teléfono:</label><br />
                <input id="telefono" type="number" {...register("telefono")} placeholder="Teléfono del cliente" />
                {errors.telefono && <p style={{ color: 'red' }}>El teléfono es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="curp">CURP:</label><br />
                <input id="curp" {...register("curp")} placeholder="Número de CURP del cliente" />
                {errors.curp && <p style={{ color: 'red' }}>El número de CURP es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="estado">Estado:</label><br />
                <input id="estado" {...register("estado")} placeholder="Estado del cliente" />
                {errors.estado && <p style={{ color: 'red' }}>El estado es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="ciudad">Ciudad:</label><br />
                <input id="ciudad" {...register("ciudad")} placeholder="Ciudad del cliente" />
                {errors.ciudad && <p style={{ color: 'red' }}>La ciudad es requerida</p>}
              </div>

              <div className='form-group' style={{ display: "none" }}>
                <label htmlFor="latitud">Latitud:</label><br />
                <input id="latitud" {...register("latitud")} value={latLng.lat} readOnly />
                {errors.latitud && <p style={{ color: 'red' }}>La latitud es requerida</p>}
              </div>

              <div className='form-group' style={{ display: "none" }}>
                <label htmlFor="longitud">Longitud:</label><br />
                <input id="longitud" {...register("longitud")} value={latLng.lng} readOnly />
                {errors.longitud && <p style={{ color: 'red' }}>La longitud es requerida</p>}
              </div>

              <div className="form-group">
                <label htmlFor="codigo_postal">Codigo postal:</label><br />
                <input id="codigo_postal" {...register("codigo_postal")} placeholder="Codigo postal del cliente" />
                {errors.codigo_postal && <p style={{ color: 'red' }}>El codigo postal es requerida</p>}
              </div>

              {/*<div className="form-group">
                <label htmlFor="tipocliente_id">Tipo de cliente:</label><br />
                <select id="tipocliente_id" {...register("tipocliente_id", { required: false })}>
                  <option value="">Selecciona un tipo de cliente (opcional)</option>
                  {tipoClientes.length > 0 ? (
                    tipoClientes.map((tipocliente) => (
                      <option key={tipocliente.tipocliente_id} value={tipocliente.tipocliente_id}>
                        {tipocliente.nombre}
                      </option>
                    ))
                  ) : (
                    <option disabled>No hay grupos disponibles</option>
                  )}
                </select>
              </div>*/}
            </div>

            <div className={`${style.formularioDM} `} style={{ paddingBottom: '50px' }}>
              <div className="form-group">
                <label htmlFor="direccion">Dirección:</label><br />
                <GoogleMapSearch setAddress={setAddress} setLatLng={setLatLng} />
                {errors.direccion && <p style={{ color: 'red' }}>La dirección es requerida</p>}
              </div>
              <div style={{ height: '350px', width: '100%', border: '2px solid #ccc', borderRadius: '20px' }}>
                <GoogleMap
                  center={latLng}
                  zoom={15}
                  mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '20px' }}
                >
                  <Marker position={latLng} />
                </GoogleMap>
              </div>
            </div>
          </div>

          <div className={`${style.formularioCliente}`} style={{ marginTop: '40px' }}>
            <div className={`${style.headerFormulario} `}>
              <h3>Datos del trabajador</h3>
            </div>
            <div className={`${style.formulario} `}>
              <div className="form-group">
                <label htmlFor="trabajador_nombre">Nombre del Trabajador:</label><br />
                <input id="trabajador_nombre" {...register("trabajador_nombre")} placeholder="Nombre del trabajador" />
                {errors.trabajador_nombre && <p style={{ color: 'red' }}>El nombre del trabajador es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="trabajador_empresa">Empresa:</label><br />
                <input id="trabajador_empresa" {...register("trabajador_empresa")} placeholder="Empresa en la que trabaja el trabajador" />
                {errors.trabajador_empresa && <p style={{ color: 'red' }}>La empresa es requerida</p>}
              </div>

              <div className="form-group">
                <label htmlFor="trabajador_telefono_rel">Teléfono:</label><br />
                <input id="trabajador_telefono_rel" {...register("trabajador_telefono_rel")} placeholder="Teléfono de la relación del trabajador" />
                {errors.trabajador_telefono_rel && <p style={{ color: 'red' }}>El teléfono de la relación es requerido</p>}
              </div>

              <div className='form-group' style={{ display: "none" }}>
                <label htmlFor="latitud_empresa">Latitud:</label><br />
                <input id="latitud_empresa" {...register("latitud_empresa")} value={latLng2.lat} readOnly />
                {errors.latitud && <p style={{ color: 'red' }}>La latitud es requerida</p>}
              </div>

              <div className='form-group' style={{ display: "none" }}>
                <label htmlFor="longitud_empresa">Longitud:</label><br />
                <input id="longitud_empresa" {...register("longitud_empresa")} value={latLng2.lng} readOnly />
                {errors.longitud && <p style={{ color: 'red' }}>La longitud es requerida</p>}
              </div>
            </div>
            <div className={`${style.formularioDM} `} style={{ paddingBottom: '10px' }}>
              <div className="form-group">
                <label htmlFor="direccion">Dirección de la empresa:</label><br />
                <GoogleMapSearch setAddress={setAddress2} setLatLng={setLatLng2} />
                {errors.direccion && <p style={{ color: 'red' }}>La dirección es requerida</p>}
              </div>
              <div style={{ height: '350px', width: '100%', border: '2px solid #ccc', borderRadius: '20px' }}>
                <GoogleMap
                  center={latLng2}
                  zoom={15}
                  mapContainerStyle={{ height: '100%', width: '100%', borderRadius: '20px' }}
                >
                  <Marker position={latLng2} />
                </GoogleMap>
              </div>
            </div>
            <div className={`${style.formulario} `}>
              <div className="form-group">
                <label htmlFor="trabajador_telefono">Teléfono de la empresa:</label><br />
                <input id="trabajador_telefono" {...register("trabajador_telefono")} placeholder="Teléfono de la empresa" />
                {errors.trabajador_telefono && <p style={{ color: 'red' }}>El teléfono es requerido</p>}
              </div>
              <div className="form-group">
                <label htmlFor="trabajador_entre">Referencias de dirección:</label><br />
                <input id="trabajador_entre" {...register("trabajador_entre")} placeholder="Entre qué calles está la empresa" />
                {errors.trabajador_entre && <p style={{ color: 'red' }}>Las calles son requeridas</p>}
              </div>

              <div className="form-group" style={{ display: "none" }}>
                <label htmlFor="trabajador_colonia">Colonia de la empresa:</label><br />
                <input id="trabajador_colonia" {...register("trabajador_colonia")} placeholder="Colonia de la empresa" />
                {errors.trabajador_colonia && <p style={{ color: 'red' }}>La colonia es requerida</p>}
              </div>

              <div className="form-group">
                <label htmlFor="trabajador_puesto">Puesto del trabajador:</label><br />
                <input id="trabajador_puesto" {...register("trabajador_puesto")} placeholder="Puesto del trabajador" />
                {errors.trabajador_puesto && <p style={{ color: 'red' }}>El puesto es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="trabajador_antiguedad">Años de antigüedad:</label><br />
                <input id="trabajador_antiguedad" {...register("trabajador_antiguedad")} placeholder="Años de antigüedad del trabajador" />
                {errors.trabajador_antiguedad && <p style={{ color: 'red' }}>Los años de antigüedad son requeridos</p>}
              </div>
            </div>
          </div>

          <div className={`${style.formularioCliente} table-responsive bg-white`} style={{ marginTop: '40px' }}>
            <div className={`${style.referencias}`}>
              <div className={`${style.headerFormulario} `}>
                <h3>Referencias</h3>
              </div>
              <div className={`${style.referenciasBoton}`}>
                <button className="botonReferencias" type='button' onClick={openModalHandler}>
                  <FaPlus
                    color={'white'}
                    size={'30'}
                  /></button>
              </div>
              <div className={`${style.dataTable} `}>
                <DataTable columns={columns} data={dataReferencia} />
                {dataReferencia.length === 0 && (
                  <tr>
                    <td colSpan={6} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                      <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                    </td>
                  </tr>
                )}
              </div>
            </div>
          </div>

          <div className={`${style.formularioCobranza}`} style={{ marginTop: '40px' }}>
            <div className={`${style.headerFormulario} `}>
              <h3>Horario de cobro en casa</h3>
            </div>
            <div className={`${style.formularioCobros} `}>
              <div>
                <div className={`${style.formularioHora} `}>
                  <div className="form-group">
                    <label htmlFor="cobro_hora">Horario (Desde):</label><br />
                    <input id="cobro_hora" type="time" {...register("cobro_hora")} placeholder="Hora de cobro" />
                    {errors.cobro_hora && <p style={{ color: 'red' }}>La hora de cobro es requerida</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="cobro_hora2">Horario (Hasta):</label><br />
                    <input id="cobro_hora2" type="time" {...register("cobro_hora2")} placeholder="Hora de cobro en la empresa" />
                    {errors.cobro_hora2 && <p style={{ color: 'red' }}>La hora de cobro en la empresa es requerida</p>}
                  </div>
                </div>
                <div className="form-group" style={{ marginLeft: '20px', marginTop: '20px' }}>
                  <label htmlFor="tipo_casa">Tipo de domicilio:</label><br />
                  <select id="tipo_casa" {...register("tipo_casa", { required: false })}>
                    <option value="">Selecciona un tipo de domicilio (opcional)</option>
                    <option value="propia">Casa propia</option>
                    <option value="renta">Casa de renta</option>
                  </select>
                </div>
              </div>

              <div className="form-group" style={{ marginLeft: '20px' }}>
                <label htmlFor="cobro_casa">Detalles adicionales:</label><br />
                <textarea id="cobro_casa" {...register("cobro_casa")} placeholder="Detalles adicionales al cobro." />
              </div>
            </div>
          </div>

          <div className={`${style.formularioCobranza}`} style={{ marginTop: '40px' }}>
            <div className={`${style.headerFormulario} `}>
              <h3>Horario de cobro en trabajo</h3>
            </div>
            <div className={`${style.formularioCobrosTrabajo} `}>
              <div className={`${style.formularioHora} `}>
                <div className="form-group">
                  <label htmlFor="trabajo_hora">Horario (Desde):</label><br />
                  <input id="trabajo_hora" type="time" {...register("trabajo_hora")} />
                  {errors.cobro_hora && <p style={{ color: 'red' }}>La hora de cobro es requerida</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="trabajo_hora2">Horario (Hasta):</label><br />
                  <input id="trabajo_hora2" type="time" {...register("trabajo_hora2")} />
                  {errors.cobro_hora2 && <p style={{ color: 'red' }}>La hora de cobro en la empresa es requerida</p>}
                </div>
              </div>
              <div className="form-group" style={{ marginLeft: '20px' }}>
                <label htmlFor="cobro_trabajo">Detalles adicionales:</label><br />
                <textarea id="cobro_trabajo" {...register("cobro_trabajo")} placeholder="Detalles adicionales al cobro." />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal
        className='modal'
        show={modalShow}
        setModalShow={setModalShow}
        close={closeModalHandler}
        setDataReferencias={setDataReferencias}
        data={dataReferencia}
      ></Modal>
    </div>
  );
};

export default CreateClientePage;

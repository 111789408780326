import React, { useRef, useState, useEffect } from 'react';
import { NavLink, Navigate } from 'react-router-dom';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { NotificationManager } from 'react-notifications';
import { NotificationContainer } from 'react-notifications';
import axiosConfig from '../../../utils/axiosConfig';
import { storeToken } from '../../../utils/authService';
import jwt_decode from 'jwt-decode';
import style from './LoginPage.module.sass';

//import logoLogin from './../../assets/logoLogin.png';

import 'animate.css';

export const LoginPage = () => {
  const emailRef = useRef('');
  const passwordRef = useRef('');
  const [token, setToken] = useState(null);

  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { value: user } = emailRef.current;
    const { value: password } = passwordRef.current;

    if (user === '') {
      setEmailValid(true);
      setPasswordValid(true);
      return;
    } else {
      setEmailValid(false);
    }

    if (password === '') {
      return;
    } else {
      setPasswordValid(false);
    }

    axiosConfig({
      method: 'POST',
      url: '/auth',
      data: {
        user,
        password,
      },
    })
      .then(({ token }) => {

        // Decodificar token
        const data = jwt_decode(token);
        console.log("data: ",data);
        if (data.rol !== "administrador") {
          NotificationManager.error('No tienes los permisos requeridos para iniciar sesión');
        } else{
          storeToken(token);
          setToken(token);
        }

      })
      .catch((err) => {
        console.log(err);
        setEmailValid(true);
        setPasswordValid(true);
      });
  };

  if (token) {
    return <Navigate to='/panel' replace={true} />;
  }

  return (
    <>
      <div className={style.back} />
      <div className='selection:bg-rose-500 selection:text-white '>
        <div className='flex items-center justify-center h-screen overflow-auto animate__animated animate__fadeIn animate__fast'>
          <div className='w-96 max-h-screen rounded-3xl mx-auto overflow-hidden shadow-xl sm:max-h-fit'>
            <div className={`${style.titulo_div} relative h-20 rounded-bl-4xl`}>
              <h3 className={`${style.h2_sesion}`}>Inicio de sesión</h3>

            </div>

            <div className='relative px-10 bg-white pt-5 pb-8 rounded-tr-4xl'>
              <div className='flex justify-center items-center mb-4' >
                {
                  //<img src={logoLogin} alt={logoLogin} />
                }
              </div>

              <form className='mt-1' onSubmit={handleSubmit}>
                <div className='relative'>
                  <input autoComplete='off' ref={emailRef} type='text' id='emailInput' placeholder='Username'
                    className={`${style.input_usuario} ${emailValid
                      ? 'peer h-10 w-full border-b-rose-600 border-t-0 border-x-0 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600 animate__animated animate__shakeX'
                      : 'peer h-10 w-full border-y-0 border-x-0 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500'
                      } `}
                  />
                  <p id='validationEmail' className={`${emailValid ? 'text-red-800 mt-1' : 'hidden'}`}>
                    Usuario no registrado.
                  </p>
                  <label htmlFor='emailInput'
                    className='absolute left-2 -top-6 text-black-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 
                    peer-placeholder-shown:top-2 peer-focus:-top-6 peer-focus:text-black-500 peer-focus:text-sm'>
                    Usuario
                  </label>
                </div>

                <div className='mt-12 relative'>
                  <input
                    ref={passwordRef}
                    type={open === false ? 'password' : 'text'}
                    id='inputPassword'
                    className={`${style.input_usuario} ${passwordValid
                      ? 'peer h-10 w-full border-b-rose-600 border-t-0 border-x-0 text-gray-900 placeholder-transparent focus:outline-none focus:border-rose-600 animate__animated animate__shakeX'
                      : 'peer h-10 w-full border-y-0 border-x-0 text-gray-900 placeholder-transparent focus:outline-none focus:border-blue-500'
                      }`}
                    placeholder='Contraseña'
                  />
                  <div className='text-2xl relative -top-[38px] left-[270px]'>
                    {open === false ? (
                      <AiFillEyeInvisible color='#555555' onClick={toggle} />
                    ) : (
                      <AiFillEye color='#555555' onClick={toggle} />
                    )}
                  </div>
                  <p
                    id='validationPassword'
                    className={`${passwordValid ? 'text-red-800 -mt-7' : 'hidden'}`}
                  >
                    Contraseña invalida.
                  </p>

                  <label
                    htmlFor='inputPassword'
                    className='absolute left-2 -top-6 text-black-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-6 peer-focus:text-black-500 peer-focus:text-sm'
                  >
                    Contraseña
                  </label>
                </div>

                <input
                  type='submit'
                  value='Ingresar'
                  className={`${style.boton_sesion} mt-12 px-4 py-2 rounded border-none text-white font-semibold text-center block w-full focus:outline-none focus:ring focus:ring-offset-2 focus:ring-rose-500 focus:ring-opacity-80 cursor-pointer`}
                />
              </form>
              {
                /*<NavLink
                  to='/ForgottenPassword'
                  className='mt-4 block text-sm text-center font-medium text-blue-900 hover:underline focus:outline-none focus:ring-2 focus:ring-rose-500'
                >
                  ¿Olvidaste tu contraseña ?
                </NavLink>*/
              }
            </div>
          </div>
        </div>
      </div>
      
    <NotificationContainer/>
    </>
  );
};

export default LoginPage;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./TipoClientesPage.module.sass";
import axiosConfig from '../../utils/axiosConfig';
import axios from "axios"; // Importa axios
import { FaTrash, FaPencilAlt, FaCheck } from 'react-icons/fa';
import { AiFillEye } from 'react-icons/ai';
import { Pagination } from '../../components/pagination/Pagination';
import { Filter } from '../../components/filter/Filter';
import { NotificationManager } from "react-notifications";
import Swal from 'sweetalert2'; // Importa SweetAlert
import ModalTipoClientes from '../../components/modal_tc/modal_agregar/Modal_TipoCliente';
import ModalTipoClientesEditar from '../../components/modal_tc/modal_editar/Modal_TipoClienteEditar';
import { FaUserTie } from "react-icons/fa6";

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const TipoClientesPage = () => {
  const navigate = useNavigate();

  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const [lClientes, setLClientes] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  const [modalShowEditar, setModalShowEditar] = useState(false);

  const [searchBtn, setSearchBtn] = useState(false);
  const [activeFilter, setActiveFilter] = useState(true);

  const [filter, setFilter] = useState(initialFilter);
  const mensajeVacio = '';

  const [refreshClientes, setRefreshClientes] = useState(false);

  const [detailCliente, setDetailClientes] = useState({});

  useEffect(() => {
    if (refreshClientes) {
      getClientes();
    }
    setRefreshClientes(false);
  }, [refreshClientes]);

  useEffect(() => {
    setFilter(() => {
      return { ...filter, status: activeFilter };
    });
    setRefreshClientes(true);
  }, [activeFilter]);

  useEffect(() => {
    if (searchBtn && searchInput != null) {
      setFilter(() => {
        return { ...filter, search: searchInput };
      });
      setRefreshClientes(true);
      setSearchBtn(false);
    }
  }, [searchBtn]);

  useEffect(() => {
    const handleGrupoAgregado = () => {
      getClientes(); // Llama a la función para actualizar la tabla
    };
    const handleGrupoEditado = () => {
      getClientes(); // Llama a la función para actualizar la tabla
    };
    // Agrega el event listener para el evento personalizado
    window.addEventListener('tipoClienteAgregado', handleGrupoAgregado);
    window.addEventListener('tipoClienteEditado', handleGrupoEditado);
    // Limpia el event listener cuando el componente se desmonte para evitar fugas de memoria
    return () => {
      window.removeEventListener('tipoClienteAgregado', handleGrupoAgregado);
      window.removeEventListener('tipoClienteEditado', handleGrupoEditado);
    };
  }, []);


  const [pageCount, setPageCount] = useState(0);

  const getClientes = () => {
    axiosConfig({
      method: 'GET',
      url: '/tipocliente',
      params: { ...filter },
    })
      .then((result) => {
        console.log("Carga de datos: ", result);

        setLClientes(result.rows);
        setPageCount(Math.ceil(result.count / 10));
      })
      .catch((err) => console.log(err));
  };

  const handlePageClick = (event) => {
    setFilter(() => {
      return { ...filter, page: event.selected + 1 };
    });
    setRefreshClientes(true);
  };

  const closeModalHandler = () => {
    setModalShow(false);
  };

  const closeModalHandlerEditar = () => {
    setModalShowEditar(false);
  };

  //Modal 
  const [dataReferencia, setDataReferencias] = useState([]);
  const [dataReferenciaEditar, setDataReferenciasEditar] = useState([]);

  const [tipoclienteId, setGrupoId] = useState(null);

  // Función para abrir el modal y establecer el ID del tipoCliente seleccionado
  const openModalHandler = (typeclienteId) => {
    setGrupoId(typeclienteId);
    setModalShowEditar(true);
    console.log('tipoClienteId:', typeclienteId);
  };

  const cambioActivo = async (tipocliente_id, data, actionType) => {
    try {
      let confirmationMessage1 = '';
      let confirmationMessage2 = ''; // Inicializa el mensaje de confirmación

      // Define el mensaje de confirmación en función del tipo de acción
      if (actionType === 'desactivar') {
        confirmationMessage1 = 'Desactivar Tipo de cliente';
        confirmationMessage2 = '¿Estás seguro de que deseas desactivarlo?';
      } else if (actionType === 'activar') {
        confirmationMessage1 = 'Activar Tipo de cliente';
        confirmationMessage2 = '¿Estás seguro de que deseas activarlo?';
      }

      const confirmed = await Swal.fire({ // Utiliza Swal.fire para mostrar SweetAlert
        title: confirmationMessage1,
        text: confirmationMessage2,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, realizar acción',
        cancelButtonText: 'Cancelar',
      });

      if (!confirmed.isConfirmed) return; // Si el usuario cancela, no hacer nada

      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };
      // Realiza la solicitud PUT a la API para actualizar el estado del usuario
      await axios.put(`${process.env.REACT_APP_API_URL}tipocliente/estatus/${tipocliente_id}`, data, headers);

      getClientes();

      NotificationManager.success('Estado del tipo de cliente actualizado exitosamente');

      // Redirige a la página deseada
      //navigate('/panel/users');
    } catch (error) {
      console.error('Error al actualizar el estado del cliente:', error);
      NotificationManager.error('Error al actualizar el estado del tipo de cliente');
    }
  };


  return (
    <div className={`${style.TipoClientesPage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <FaUserTie
            className={'mx-2 mb-1'}
            color={'white'}
            size={'35'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ 'mix-blend-mode': 'difference' }}>Tipo de clientes</span>
        </h3>
      </div>


      <Filter
        setActiveFilter={setActiveFilter}
        setSearchInput={setSearchInput}
        setSearchBtn={setSearchBtn}
        setModulo='tipocliente'
      />

      <div className={`${style.table} table-responsive bg-white`}>
        <table className='table table-borderless border-bottom'>
          <thead>
            <tr className='text-start border-bottom'>
              <th className='text-center'>Nombre</th>
              <th className='text-center'>Descripcion</th>
              <th className='text-center'>Activo</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {lClientes.length > 0 &&
              lClientes.map((u) => (
                <tr className='text-start border-bottom align-middle' key={u.tipocliente_id}>
                  <td className='text-center'>{u.nombre}</td>
                  <td className='text-center'>{u.descripcion}</td>
                  <td className='text-center'>
                    {u.activo ? (
                      <span className='badge bg-success rounded-pill'>Activo</span>
                    ) : (
                      <span className='badge bg-danger rounded-pill'>Inactivo</span>
                    )}
                  </td>
                  <td className='text-center'>
                    <button
                      id='btnEdit'
                      className='btn btn-sm btn-warning mx-1'
                      onClick={() => {
                        openModalHandler(u.tipocliente_id)
                        console.log(u.tipocliente_id);
                      }}>
                      <FaPencilAlt size={25} color='white' />
                    </button>
                    {u.activo === true ? (
                      <button
                        id='btnExc'
                        className='btn btn-sm btn-danger mx-1'
                        onClick={() => {
                          cambioActivo(u.tipocliente_id, { activo: false }, 'desactivar')
                          console.log(u.tipocliente_id);
                          //openModalHandlerAD(u);
                        }}>
                        <FaTrash size={25} color='white' />
                      </button>
                    ) : (
                      <button
                        id='btnExc'
                        className='btn btn-sm btn-success mx-1'
                        onClick={() => {
                          cambioActivo(u.tipocliente_id, { activo: true }, 'activar')
                          console.log(u.tipocliente_id);
                          //openModalHandlerAD(u);
                        }}
                      >
                        <FaCheck size={25} color='white' />
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            {lClientes.length === 0 && (
              <tr>
                <td colSpan={6} style={{ textAlign: 'center', fontSize: 20 + 'px' }}>
                  <span style={{ color: '#aaa' }}>{mensajeVacio || 'No se encontraron datos.'}</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
      </div>
      <ModalTipoClientes
        className='modal'
        show={modalShow}
        setModalShow={setModalShow}
        close={closeModalHandler}
        setDataReferencias={setDataReferencias}
        data={dataReferencia}
      ></ModalTipoClientes>

      <ModalTipoClientesEditar
        className='modal'
        show={modalShowEditar}
        setModalShow={setModalShowEditar}
        close={closeModalHandlerEditar}
        setDataReferencias={setDataReferenciasEditar}
        data={dataReferenciaEditar}
        typeclienteId={tipoclienteId}
      ></ModalTipoClientesEditar>
    </div>
  );
};

export default TipoClientesPage;

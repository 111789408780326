import zIndex from '@mui/material/styles/zIndex';
import React, { useState, useEffect } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const GoogleMapSearch = ({ setAddress, dir, setLatLng }) => {
    const [selectedAddress, setSelectedAddress] = useState('');

    useEffect(() => {
        setSelectedAddress(dir);
      }, []);

    const handleSelect = async (address) => {
        setSelectedAddress(address);
        setAddress(address);
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            setLatLng(latLng);
        } catch (error) {
            console.error('Error fetching geolocation:', error);
        }
        
    };


    return (
        <PlacesAutocomplete value={selectedAddress} onChange={setSelectedAddress} onSelect={handleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <input {...getInputProps({ placeholder: 'Dirección...' })} />
                    <div>
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map((suggestion) => {
                            const style = {
                                backgroundColor: suggestion.active ? '#41b6e6' : '#fff',
                            };
                            return (
                                <div style={{ zIndex: 1 }} key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })}>
                                    {suggestion.description}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

export default GoogleMapSearch;

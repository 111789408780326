import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Importa axios

import style from "./CreateUserPage.module.sass";

import { BiUserPlus } from "react-icons/bi";
import { MdArrowBackIosNew } from 'react-icons/md'

import { useForm } from "react-hook-form";

import { NotificationManager } from "react-notifications";

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const CreateUserPage = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const backlUser = () => {
    navigate("/panel/users");
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    // Define las reglas de validación utilizando la librería react-hook-form
    register('nombre', { required: true });
    register('apellido', { required: true });
    register('usuario', { required: true });
    register('password', { required: true });
    register('rol', { required: true });
  }, [register]);

  //Obtener grupos
  const [grupos, setGrupos] = useState([]);
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    const fetchGrupos = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filter }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}grupo`, headers);
        console.log("Respuesta completa de la API:", response.data);
        setGrupos(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los grupos:", error);
      }
    };

    if (token) {
      fetchGrupos();
    }
  }, [token]);


  const onSubmit = async (data) => {
    try {
      // Convertir el valor de "grupo" a un número si es un string
      if (typeof data.grupo === 'string') {
        data.grupo = parseInt(data.grupo);
      }

      const headers = {
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded",
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };
      // Realiza la solicitud POST a la API
      console.log("data", data);
      console.log("token", token);
      console.log("header", headers);
      await axios.post(process.env.REACT_APP_API_URL + 'usuarios', data, headers);
      NotificationManager.success('Usuario agregado exitosamente');
      // Redirige a la página deseada
      navigate('/panel/users');
    } catch (error) {
      console.error('Error al agregar usuario:', error);
      NotificationManager.error('Error al agregar usuario');
    }
  };

  return (
    <div className={`${style.CreateUserPage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <BiUserPlus
            className={'mx-2 mb-1'}
            color={'white'}
            size={'40'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ mixBlendMode: 'difference' }}>Agregar nuevo usuario</span>
        </h3>
      </div>

      {/* Agrega el formulario para ingresar los datos del usuario */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${style.box} bg-white`}>
          <div className={`${style.boxBarra} fw-normal p-2 border-secondary`}>
            <h3 className='h3 fw-bold' style={{ fontSize: 20 + 'px' }}>
              <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer', marginRight: '10px' }} />
            </h3>
            <button type="submit">
              <BiUserPlus
                color={'white'}
                size={'35'}
              />
            </button>
          </div>
        </div>
        <div className={`${style.formularioUsuarios} `}>
          <div className={`${style.headerFormulario} `}>
          </div>
          <div className={`${style.formulario} `}>
            <div class="form-group">
              <label htmlFor="nombre">Nombre:</label><br />
              <input id="nombre" {...register("nombre")} placeholder="Ej. Gaston" />
              {errors.nombre && <p style={{ color: 'red' }}>El nombre es requerido</p>}
            </div>

            <div class="form-group">
              <label htmlFor="apellido">Apellido:</label><br />
              <input id="apellido" {...register("apellido")} placeholder="Ej. Ramirez" />
              {errors.apellido && <p style={{ color: 'red' }}>El apellido es requerido</p>}
            </div>

            <div class="form-group">
              <label htmlFor="email">Email:</label><br />
              <input id="email" {...register("email")} placeholder="Ej. G.Ramirez@ejemplo.com" />
            </div>

            <div class="form-group">
              <label htmlFor="usuario">Usuario:</label><br />
              <input id="usuario" {...register("usuario")} placeholder="Ej. G Ramirez" />
              {errors.usuario && <p style={{ color: 'red' }}>El usuario es requerido</p>}
            </div>

            <div class="form-group">
              <label htmlFor="contraseña">Contraseña:</label><br />
              <input id="password" {...register("password")} placeholder="***************" type="password" />
              {errors.password && <p style={{ color: 'red' }}>La contraseña es requerida</p>}
            </div>

            <div class="form-group">
              <label htmlFor="telefono">Teléfono:</label><br />
              <input id="telefono" {...register("telefono")} placeholder="Ej. 6621098765" />
            </div>

            <div class="form-group">
              <label htmlFor="direccion">Dirección:</label><br />
              <input id="direccion" {...register("direccion")} placeholder="Ej. Las Palmas #97" />
            </div>

            <div class="form-group">
              <label htmlFor="estado">Estado:</label><br />
              <input id="estado" {...register("estado")} placeholder="Ej. Sonora" />
            </div>

            <div class="form-group">
              <label htmlFor="ciudad">Ciudad:</label><br />
              <input id="ciudad" {...register("ciudad")} placeholder="Ej. Hermosillo" />
            </div>

            <div class="form-group">
              <label htmlFor="codigo_postal">Código Postal:</label><br />
              <input id="codigo_postal" {...register("codigo_postal")} placeholder="Ej. 83100" />
            </div>

            <div className="form-group">
              <label htmlFor="rol">Rol:</label><br />
              <select id="rol" {...register("rol")} defaultValue="">
                <option value="administrador">Administrador</option>
                <option value="cobrador">Cobrador</option>
              </select>
              {errors.rol && <p style={{ color: 'red' }}>El rol es requerido</p>}
            </div>

            <div className="form-group" style={{ display: watch('rol') === 'cobrador' ? 'block' : 'none' }} >
              <label htmlFor="grupo">Grupo:</label>
              <br />
              <select id="grupo" {...register("grupo", { required: false })}>
                <option value="">Selecciona un grupo (opcional)</option>
                {grupos.length > 0 ? (
                  grupos.map((grupo) => (
                    <option key={grupo.grupo_id} value={grupo.grupo_id}>
                      {grupo.nombre}
                    </option>
                  ))
                ) : (
                  <option disabled>No hay grupos disponibles</option>
                )}
              </select>
            </div>

          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateUserPage;

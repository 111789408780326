import React, { useState } from 'react';
import '../modal_agregar/Modal_Convenios.css';
import { FaTrash } from 'react-icons/fa';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';



const Modal = (props) => {
    const close = props.close;

    const [inputValues, setInputValues] = useState({
        codigo: '',
        modelo: '',
        color: '',
        precio: '',
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        setInputValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleAddRow = () => {
        // Validar que todos los campos estén completos
        if (!inputValues.codigo || !inputValues.modelo || !inputValues.color || !inputValues.precio) {
            console.log("error al agregar referencia")
            return;
        }

        var id = 1;
        // Verificar si hay elementos en props.data
        if (props.data.length > 0) {
            // Encontrar el número mayor en el campo id
            const maxId = props.data.reduce((max, item) => (item.id > max ? item.id : max), 0);
            // Sumar 1 al número mayor para obtener el nuevo id
            id = maxId + 1;
        }

        const buttonAccion = React.createElement('button', {
            id: 'btnExc', className: 'btn btn-sm btn-danger mx-1', type: 'button',
            onClick: () => {
                // Filtrar los datos para excluir el elemento que se está eliminando
                props.setDataReferencias((prevData) => {
                    // Filtrar los datos para excluir el elemento que se va a eliminar
                    const newData = prevData.filter(item => item.id !== id);

                    return newData;
                });
                props.recalcular(true);
            },
        }, React.createElement(FaTrash, { size: 25, color: 'white' }));

        // Agregar los valores ingresados a la tabla de datos
        props.setDataReferencias((prevData) => [
            ...prevData,
            {
                codigo: inputValues.codigo,
                modelo: inputValues.modelo,
                color: inputValues.color,
                precio: inputValues.precio,
                accion: buttonAccion,
                id: id
            },
        ]);

        props.recalcular(true);

        // Vaciar los campos de entrada después de agregar
        setInputValues({
            codigo: '',
            modelo: '',
            color: '',
            precio: '',
        });

        close();
    };

    const handleCancel = () => {
        // Vaciar los campos de entrada al cancelar
        setInputValues({
            codigo: '',
            modelo: '',
            color: '',
            precio: '',
        });

        close();
    };

    return (
        <div className="modal-container" style={{ display: props.show ? 'block' : 'none' }}>
            <div className="modal-background" onClick={close}></div>
            <div className="modal-wrapper">
                <div className="headerFormulario">
                    <h3>Agregar articulo a la compra</h3>
                </div>
                <div className='formularioAgregarArticulo'>
                    <div className="form-group">
                        <label htmlFor="codigo">Código:</label><br />
                        <input
                            type="text"
                            name="codigo"
                            value={inputValues.codigo}
                            onChange={handleChange}
                            placeholder="Código"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="modelo">Modelo:</label><br />
                        <input
                            type="text"
                            name="modelo"
                            value={inputValues.modelo}
                            onChange={handleChange}
                            placeholder="Modelo"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="color">Color:</label><br />
                        <input
                            type="text"
                            name="color"
                            value={inputValues.color}
                            onChange={handleChange}
                            placeholder="Color"
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="precio">Precio:</label><br />
                        <input
                            type="number"
                            name="precio"
                            value={inputValues.precio}
                            onChange={handleChange}
                            placeholder="Precio"
                        />
                    </div>
                </div>
                <div className="modal-botones">
                    <div className="divbtn">
                        <button className="btn btn-success mx-1 boton-modal" type='button' onClick={handleAddRow}>Agregar</button>
                        <button className="btn btn-danger mx-1 boton-modal" type='button' onClick={handleCancel}> Cancel </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;

import React from 'react'
import './Pagination.css'

import ReactPaginate from "react-paginate";

import PropTypes from 'prop-types'

export const Pagination = ( props ) => {
   const { pageCount, handlePageClick } = props
   return (
      <div id='divpaginate' className=''>
         <ReactPaginate 
            breakLabel="..."
            nextLabel=">"
            previousLabel="<"
            nextClassName="btn"
            pageClassName="btn"
            previousClassName="btn"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            renderOnZeroPageCount={null}
         /> 
      </div>
   );
}

Pagination.propTypes = {
   className : PropTypes.string,
}

Pagination.defaultProps = {
   className: ""
}
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "./UpdateUserPage.module.sass";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { MdArrowBackIosNew } from 'react-icons/md'
import { FaPencilAlt } from 'react-icons/fa';

const initialFilter = {
  search: null,
  status: true,
  order: 'desc',
  limit: 10,
  page: 1,
};

const UpdateUserPage = () => {
  const { id } = useParams(); // Obtiene el usuario_id de la URL
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [formData, setFormData] = useState({});

  const [token, setToken] = useState(null);

  const backlUser = () => {
    navigate("/panel/users");
  }

  useEffect(() => {
    setToken(sessionStorage.getItem('token'));
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm();

  //Obtener grupos
  const [grupos, setGrupos] = useState([]);
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    const fetchGrupos = async () => {
      try {
        const headers = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          params: { ...filter }
        };

        const response = await axios.get(`${process.env.REACT_APP_API_URL}grupo`, headers);
        console.log("Respuesta completa de la API:", response.data);
        setGrupos(response.data.rows);
      } catch (error) {
        console.error("Error al obtener los grupos:", error);
      }
    };

    if (token) {
      fetchGrupos();
    }
  }, [token]);

  useEffect(() => {
    // Define las reglas de validación utilizando la librería react-hook-form
    register('nombre', { required: true });
    register('apellido', { required: true });
    register('usuario', { required: true });
    register('rol', { required: true });
  }, [register]);

  useEffect(() => {
    // Verifica que token tenga un valor antes de hacer la solicitud
    if (token) {
      const fetchUserData = async () => {
        try {
          const headers = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token,
            },
          };

          const response = await axios.get(`${process.env.REACT_APP_API_URL}usuarios/${id}`, headers);
          console.log("useEffect: ", response);
          setUserData(response.data);

          console.log("UserData: ", userData);

          setValue("nombre", response.data.nombre);
          setValue("apellido", response.data.apellido);
          setValue("email", response.data.email);
          setValue("usuario", response.data.usuario);
          setValue("telefono", response.data.telefono);
          setValue("direccion", response.data.direccion);
          setValue("estado", response.data.estado);
          setValue("ciudad", response.data.ciudad);
          setValue("codigo_postal", response.data.codigo_postal);
          setValue("rol", response.data.rol);
        } catch (error) {
          console.error('Error al obtener los datos del usuario:', error);
        }
      };

      fetchUserData();
    }
  }, [id, token]); // Ahora token es una dependencia del useEffect

  const onEdit = async (data) => {
    try {
      console.log("data", data);
      // Convertir el valor de "grupo" a un número si es un string
      if (typeof data.grupo === 'string') {
        data.grupo = parseInt(data.grupo);
      }

      if (!data.password) {
        delete data.password; // Omitir el campo de contraseña si está vacío
      }

      const headers = {
        headers: {
          //"Content-Type": "application/x-www-form-urlencoded",
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token,
        },
      };
      // Realiza la solicitud POST a la API
      console.log("data", data);
      console.log("token", token);
      console.log("header", headers);
      await axios.put(`${process.env.REACT_APP_API_URL}usuarios/${id}`, data, headers);
      NotificationManager.success('Usuario modificado');
      // Redirige a la página deseada
      navigate('/panel/users');
    } catch (error) {
      console.error('Error al modificar usuario:', error);
      NotificationManager.error('Error al modificar usuario');
    }
  };

  return (
    <div className={`${style.UpdateUserPage} `}>
      <div className='d-flex justify-content-between mb-4'>
        <h3 className='h3 fw-bold' style={{ fontSize: 30 + 'px' }}>
          <FaPencilAlt
            className={'mx-2 mb-1 '}
            color={'white'}
            size={'30'}
            style={{ mixBlendMode: 'difference' }}
          />
          <span style={{ mixBlendMode: 'difference' }}>Editar usuario</span>
        </h3>
      </div>

      {/* Renderiza el formulario de actualización de usuario con los datos cargados del usuario */}
      {userData && (
        <form onSubmit={handleSubmit(onEdit)}> {/* Cambia onSubmit a onView */}
          {/* Agrega el formulario para ingresar los datos del usuario */}
          <div className={`${style.box} bg-white`}>
            <div className={`${style.boxBarra} fw-normal p-2 border-secondary`}>
              <h3 className='h3 fw-bold' style={{ fontSize: 20 + 'px' }}>
                <MdArrowBackIosNew size={25} onClick={backlUser} style={{ cursor: 'pointer', marginRight: '10px' }} />
              </h3>
              <button type="submit">
                <FaPencilAlt
                  color={'white'}
                  size={'30'}
                />
              </button>
            </div>
          </div>
          <div className={`${style.formularioUsuarios} `}>
            <div className={`${style.headerFormulario} `}>
            </div>
            <div className={`${style.formulario} `}>
              <div className="form-group">
                <label htmlFor="nombre">Nombre:</label><br />
                <input id="nombre" {...register("nombre")} placeholder="Ej. Gaston" />
                {errors.usuario && <p style={{ color: 'red' }}>El nombre es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="apellido">Apellido:</label><br />
                <input id="apellido" {...register("apellido")} placeholder="Ej. Ramirez" />
                {errors.usuario && <p style={{ color: 'red' }}>El apellido es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email:</label><br />
                <input id="email" {...register("email")} placeholder="Ej. G.Ramirez@ejemplo.com" />
              </div>

              <div className="form-group">
                <label htmlFor="usuario">Usuario:</label><br />
                <input id="usuario" {...register("usuario")} placeholder="Ej. GRamirez" />
                {errors.usuario && <p style={{ color: 'red' }}>El usuario es requerido</p>}
              </div>

              <div className="form-group">
                <label htmlFor="contraseña">Contraseña:</label><br />
                <input
                  name="password"
                  id="password" {...register("password")} placeholder="***************" type="password" />
              </div>

              <div className="form-group">
                <label htmlFor="telefono">Teléfono:</label><br />
                <input id="telefono" {...register("telefono")} placeholder="Ej. 6621098765" />
              </div>

              <div className="form-group">
                <label htmlFor="direccion">Dirección:</label><br />
                <input id="direccion" {...register("direccion")} placeholder="Ej. Las Palmas #97" />
              </div>

              <div className="form-group">
                <label htmlFor="estado">Estado:</label><br />
                <input id="estado" {...register("estado")} placeholder="Ej. Sonora" />
              </div>

              <div className="form-group">
                <label htmlFor="ciudad">Ciudad:</label><br />
                <input id="ciudad" {...register("ciudad")} placeholder="Ej. Hermosillo" />
              </div>

              <div className="form-group">
                <label htmlFor="codigo_postal">Código Postal:</label><br />
                <input id="codigo_postal" {...register("codigo_postal")} placeholder="Ej. 83100" />
              </div>

              <div className="form-group">
                <label htmlFor="rol">Rol:</label><br />
                <select id="rol" {...register("rol")}>
                  <option value="administrador">Administrador</option>
                  <option value="cobrador">Cobrador</option>
                </select>
              </div>

              <div className="form-group" style={{ display: !watch('rol') || watch('rol') !== 'cobrador' ? 'none' : 'block' }} >
                <label htmlFor="grupo">Grupo:</label>
                <br />
                <select id="grupo" {...register("grupo", { required: false })} >
                  <option value="">Selecciona un grupo (opcional)</option>
                  {grupos.length > 0 ? (
                    grupos.map((grupo) => (
                      (grupo.grupo_id == userData.grupo) ?
                        <option key={grupo.grupo_id} value={grupo.grupo_id} selected>
                          {grupo.nombre}
                        </option> :
                        <option key={grupo.grupo_id} value={grupo.grupo_id}>
                          {grupo.nombre}
                        </option>
                    ))
                  ) : (
                    <option disabled>No hay grupos disponibles</option>
                  )}
                </select>
              </div>
              {/* Resto de los campos del formulario con valores predeterminados */}
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default UpdateUserPage;